import SubForm from './subform'
import GADataLayer from '../../core/libs/ga-data-layer'

export default class VacancySubform extends SubForm {
  static name = 'vacancy-subform'

  constructor (el, eventBus) {
    super(el, eventBus)
    this.locationRow = this.el.querySelector('.form__row--location_option')
    this.contractRow = this.el.querySelector('.form__row--contract_option')
    this.hourRow = this.el.querySelector('.form__row--hours_option')
    this.ga = new GADataLayer()

    if (!this.locationRow) {
      return
    }

    this.locationField = this.locationRow.querySelector('[name="location_option"]')
    this.contractOpts = [...this.contractRow.querySelectorAll('[name="contract_option"]')]

    if (this.hourRow) {
      this.hourOpts = [...this.hourRow.querySelectorAll('[name="hours_option"]')]
    }

    if (typeof vacancyFormOptions !== 'undefined') {
      this.formOptions = vacancyFormOptions
    }

    if (this.locationField) {
      this.locationField.addEventListener('change', (event) => {
        this.emitLocationChangeEvent(event)
      })
    }

    this.setRequired(this.locationRow)
    this.setRequired(this.contractRow)
    this.setRequired(this.hourRow)

    this.update()
  }

  emitLocationChangeEvent = (event) => {
    const select = event.target
    const text = select.options[select.selectedIndex].text
    this.eventBus.emit('update-location', text)
  }


  setRequired = (el) => {
    if (!el) {
      return
    }

    const label = el.querySelector('.form__label')
    label.innerHTML = label.innerHTML.split('</label>')[0]

    const inputElements = el.querySelectorAll('input, select')
    inputElements.forEach((input) => {
      input.required = true
    })
  }
}
