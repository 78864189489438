import VacancyMapWindow from './vacancy-map-window'

export default class VacancyMap {
  static name = 'vacancy-map'

  constructor(el, eventBus, locations) {
    this.el = el
    this.eventBus = eventBus

    if (!window.vacancyMapSettings) {
      console.error('No maps settings configured for this site.')
      return
    }

    this.settings = window.vacancyMapSettings
    this.locations = locations

    this.eventBus.on('close-map-windows', this.closeInfoWindows)
    this.eventBus.on('show-map-overlay', this.showOverlay)

    this.init()
  }

  init = () => {
    this.loadClusterer()

    // Note: The code below only allows one map per page
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.settings.key}&callback=initMap`
    script.defer = true
    script.async = true

    window.initMap = this.initMap

    document.head.appendChild(script)
  }

  loadClusterer = () => {
    const script = document.createElement('script')
    script.src = `/static/js/markerclustererplus.min.js`
    document.head.appendChild(script)
  }

  initMap = () => {

    class MapOverlay extends google.maps.OverlayView {
      onAdd() {
        this.div = document.createElement('div')
        this.div.classList.add('vacancy-map__overlay')
        this.getPanes().overlayLayer.appendChild(this.div)
      }

      onRemove() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div)
          this.div = null
        }
      }
    }

    this.map = new google.maps.Map(this.el, {
      center: {
        lat: this.settings.lat,
        lng: this.settings.lng
      },
      zoom: this.settings.zoom,
      maxZoom: 17,
      mapTypeControl: false,
      streetViewControl: false,
      styles: this.snazzy()
    })

    this.overlay = new MapOverlay()
    this.overlay.setMap(this.map)

    this.map.addListener('zoom_changed', () => {
      this.closeInfoWindows()
      this.hideOverlay()
    })
    this.update(this.locations, true)
  }

  showOverlay = () => {
    this.el.classList.add('vacancy-map--overlay')

    this.eventBus.off('show-map-overlay', this.showOverlay)
    this.eventBus.on('hide-map-overlay', this.hideOverlay)
  }

  hideOverlay = () => {
    this.el.classList.remove('vacancy-map--overlay')

    this.eventBus.on('show-map-overlay', this.showOverlay)
    this.eventBus.off('hide-map-overlay', this.hideOverlay)
  }

  getLabelOrigin = (location) => {
    let x = 48.5
    let y = 12.5

    return new google.maps.Point(x, y)
  }

  updateLocation = (key, location) => {
    // Add to our library of locations
    if (!this.locations[key]) {
      this.locations[key] = location
    }
    location = this.locations[key]

    // Create marker if we don't have one yet
    if (!location.marker) {
      location.marker = new google.maps.Marker({
        position: {
          lat: parseFloat(location.lat),
          lng: parseFloat(location.lng)
        },
        icon: {
          url: `/static/images/map-icon-${location.type}.svg`,
          labelOrigin: this.getLabelOrigin(location),
          size: new google.maps.Size(62, 64.99),
          scaledSize: new google.maps.Size(62, 64.99),
        },
        optimized: false
      })
    }

    // Create or update the infowindow
    // Note: Depends on location.marker
    if (!location.mapwindow) {
      location.mapwindow = new VacancyMapWindow(this.eventBus, this.map, location)
    } else {
      location.mapwindow.update(location)
    }

    // Set/update the label
    location.marker.setLabel({
      color: 'white',
      text: String(location.totalVacancies),
      fontFamily: 'Open Sans Bold',
      fontSize: '15px'
    })

    // Add marker to map
    if (!location.marker.map) {
      location.marker.setMap(this.map)
    }
  }

  update = (locations, noFitBounds) => {
    this.closeInfoWindows()

    // Update the locations we received
    for (const [key, location] of Object.entries(locations)) {
      this.updateLocation(key, location)
    }

    // Remove locations we did not receive
    for (const [key, location] of Object.entries(this.locations)) {
      if (!locations[key]) {
        location.marker.setMap(null)
      }
    }

    // Get all active markers
    let allMarkers = []
    for (const [key, location] of Object.entries(this.locations)) {
      if (location.marker.map) {
        allMarkers.push(location.marker)
      }
    }

    if (this.markerCluster) {
      this.markerCluster.clearMarkers()
    }

    this.markerCluster = new MarkerClusterer(this.map, allMarkers, {
      calculator: this.clustererCalculator,
      gridSize: 70,
      maxZoom: 16,
      styles: [{
        width: 40,
        height: 40,
      }]
    })
    if (allMarkers.length > 0 && !noFitBounds) {
      this.fitBounds()
    }
  }

  fitBounds = () => {
    if (window.innerWidth > 1024) {
      this.markerCluster.fitMapToMarkers(200)
    } else {
      this.markerCluster.fitMapToMarkers(30)
    }
  }

  closeInfoWindows = (except) => {
    for (const [key, location] of Object.entries(this.locations)) {
      if (except && key === except) {
        continue
      }
      if (location.mapwindow) {
        location.mapwindow.close()
      }
    }
  }

  clustererCalculator = (markers, numStyles) => {
    let total = 0
    for (const marker of markers) {
      total += parseInt(marker.label.text)
    }

    return {
      text: String(total),
      index: 0
    }
  }

  snazzy = () => {
    return [
      {
        'featureType': 'administrative',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#444444'
          }
        ]
      },
      {
        'featureType': 'landscape',
        'elementType': 'all',
        'stylers': [
          {
            'color': '#f3f3f3'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'all',
        'stylers': [
          {
            'saturation': -100
          },
          {
            'lightness': 45
          }
        ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'simplified'
          }
        ]
      },
      {
        'featureType': 'road.arterial',
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          },
          {
            'lightness': '0'
          }
        ]
      },
      {
        'featureType': 'transit',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          },
          {
            'lightness': '21'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'on'
          },
          {
            'color': '#cce9f9'
          }
        ]
      }
    ]
  }
}
