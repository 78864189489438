import { bareCookies } from '../../core/libs/utils'

export default class ExtendedCookieSettings {
  static name = 'extended-cookie-settings'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.cookieOptions = [...this.el.querySelectorAll('.cookie-settings__option-input')]

    this.el.addEventListener('change', (event) => {
      this.getPreferences()
      document.location.reload()
    })

    this.init()
  }

  init () {
    const cookieValue = bareCookies.get(this.el.dataset.cookieName)

    if (cookieValue != undefined) {
      const cookieSettings = JSON.parse(cookieValue)
      for(const setting in cookieSettings) {
        this.el.querySelector(`input[value="${setting}"]`).checked =  cookieSettings[setting]
      }
    }
  }

  setCookie (val) {
    const options = {
      expires: 365,
      secure: location.protocol === 'https:',
      sameSite: 'lax'
    }

    bareCookies.set(this.el.dataset.cookieName, val, options)
  }


  getPreferences = () => {
    const settings = {}
    this.cookieOptions.forEach(option => {
      settings[option.value] = option.checked
    })

    this.setCookie(settings)
  }
}
