export default class ActiveFilters {
  static name = 'active-filters'

  constructor(el, controller, eventBus) {
    this.el = el
    this.controller = controller
    this.eventBus = eventBus
    this.tagsTarget = this.el.querySelector(".active-filters__tags")
    this.clearButton = this.el.querySelector(".active-filters__clear")

    // Get copy of filter template
    this.template = this.el.querySelector(".active-filters__tag--hidden").cloneNode(true)
    this.template.classList.remove("active-filters__tag--hidden")

    // Clear template for good measure
    this.tagsTarget.innerHTML = ""

    // Keep track of listeners
    this.tags = []

    this.init()
  }

  init = () => {
    if (this.clearButton) {
      this.clearButton.addEventListener("click", this.clear)
    }
    if (this.tagsTarget) {
      this.tagsTarget.addEventListener("click", this.onTagsClick)
    }
  }

  update = (form) => {
    const checkboxes = form.querySelectorAll(".filter-form__checkbox input:checked")

    const tags = []

    for (const input of checkboxes) {
      const tag = this.template.cloneNode(true)

      // Set label
      tag.querySelector(".active-filters-tag__text").innerHTML = input.dataset.label

      tag.dataset.inputValue = input.value

      tags.push(tag)
    }

    this.tagsTarget.innerHTML = ""

    if (tags.length > 0) {
      this.el.classList.remove("active-filters--hidden")
      for (const tag of tags) {
        this.tagsTarget.appendChild(tag)
      }
    } else {
      this.el.classList.add("active-filters--hidden")
    }
  }

  onTagsClick = (e) => {
    const inputValue = e.target.dataset.inputValue
    if (inputValue) {
      this.controller.clearFilter(inputValue)
    }
  }

  clear = () => {
    this.controller.clearFilters()
  }
}
