export default class Expandable {
  static name = 'expandable'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    this.isExpanded = this.el.dataset.isExpanded === 'true'
    this.height =
      this.expandArea = this.el.querySelector('.expand-area')
    this.expandButton = this.el.querySelector('.expand-button')

    this.expandButton.addEventListener('click', () => {
      this.setIsExpanded(true)
    })
    this.eventBus.on('set-expandable', this.setIsExpanded)

    this.initExpandArea()
  }

  initExpandArea = () => {
    this.el.style.display = 'block'
    this.expandArea.style.maxHeight = 'unset'
    this.height = Math.ceil(this.expandArea.getBoundingClientRect().height)
    this.expandArea.style.maxHeight = ''
    this.el.style.display = ''

    this.updateExpandArea()
  }

  updateExpandArea = () => {
    if (this.isExpanded) {
      this.expandArea.style.maxHeight = `${this.height}px`
    } else {
      this.expandArea.style.maxHeight = ''
    }

    this.updateButton()
  }

  updateButton = () => {
    if(this.isExpanded) {
      this.expandButton.style.opacity = '0'
    } else {
      this.expandButton.style.opacity = ''
    }
  }

  setIsExpanded = (value) => {
    this.isExpanded = value
    this.updateExpandArea()
  }
}
