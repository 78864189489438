import Cookies from 'js-cookie'

let timer = ''
let retryCounter = 0

function getGAClientID() {
  if (!window.ga || !window.ga.getAll) {
    // After 2 retries, use TraceDock ID if available
    if (retryCounter > 1) {
      return Cookies.get('_tdid') || ''
    }
    return ''
  }
  const trackers = window.ga.getAll()
  if (trackers && trackers[0]) {
    return trackers[0].get('clientId')
  }
  return ''
}

function updateElements(links, inputs) {
  const clientId = getGAClientID()
  retryCounter++
  if (!clientId) {
    return
  }
  // No longer need this timer
  clearInterval(timer)

  // Update all links with the clientId
  Array.from(links)
    .forEach(link => {
      link.href = link.href + `?ga_client_id=${clientId}`
    })

  // Update all input fields
  Array.from(inputs)
    .forEach(input => {
      input.value = clientId
    })
}

export default function initialize(container) {
  const links = container.querySelectorAll('a[data-do="append-ga-client-id"]')
  const inputs = container.querySelectorAll('input[name=ga_client_id]')
  if (links || inputs) {
    timer = setInterval(() => {
      updateElements(links, inputs)
    }, 500)
  }
}
