export default class FileField {
  static name = "filefield"

  constructor (el) {
    this.el = el
    this.input = el.querySelector('input')
    this.button = el.querySelector('.form__file-button')
    this.label = el.querySelector('.form__file-name')
    this.clear = el.querySelector('.form__file-clear')

    this.input.addEventListener('change', this.onChange.bind(this))
    this.clear.addEventListener('click', this.onClear.bind(this))
  }

  onChange = () => {
    if (this.input.value !== '') {
      this.label.innerHTML = this.input.value.split( '\\' ).pop()
      this.button.classList.add('form__file-button--hidden')
      this.clear.focus()  // focus on clear button to reset validation
    }
  }

  onClear = () => {
    const e = new Event('change')
    this.input.value = ''
    this.input.dispatchEvent(e)
    this.button.classList.remove('form__file-button--hidden')
  }
}
