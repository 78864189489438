import GADataLayer from '../../core/libs/ga-data-layer'

export default class Filters {
  static name = 'filters'

  constructor(el, controller, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.controller = controller

    this.form = this.el.querySelector('.filters__form')

    this.filterContainer = this.el.querySelector('.filters__container')
    this.filterOpts = [...this.el.querySelectorAll('.filter-form__checkbox input')]
    this.filterButtons = [...this.controller.el.querySelectorAll('[data-toggle="filters__container"]')]

    this.subOpts = [...this.el.querySelectorAll('.filter-form__has-subopts')]
    this.optLabels = [...this.el.querySelectorAll('.filter-form__checkbox-text')]

    this.showButton = this.el.querySelector('.filters__button')

    this.closeButton = this.el.querySelector('.filters__close-button')
    this.searchButton = this.el.querySelector('.filter-form__button--query')
    this.searchField = this.el.querySelector('.filter-form__row--query input[name="query"]')
    this.clearButton = this.el.querySelector('.filter-form__button--reset')

    this.filterInternal = this.el.querySelector('#filter-form-internal')

    this.isTyping = null

    this.init()
    this.onFilterUpdated(this.form)

    // Call this function at the end
    this.onFiltersLoaded()
  }

  init() {
    // clear sub options when a group is deselected
    this.subOpts.forEach((item) => {
      const input = item.querySelector('input')
      input.addEventListener('change', () => {
        if (!input.checked) {
          item.querySelectorAll('.filter-form__subopts input').forEach((elm) => {
            elm.checked = false
          })
        }
      })
    })

    // Event handlers
    if (this.filterInternal) {
      this.filterInternal.addEventListener('change', () => {
        this.onFilterUpdated()
        this.controller.sendGAEvent(this.filterInternal)
      })
    }

    this.filterOpts.forEach((item) => {
      item.addEventListener('change', () => {
        this.onFilterUpdated()
        this.controller.sendGAEvent(item)
      })
    })

    this.searchButton.addEventListener('click', (e) => {
      e.preventDefault()
      e.target.blur()
      this.onFilterUpdated()
      this.controller.sendGAEvent(this.searchField)
    })

    this.clearButton.addEventListener('click', (e) => {
      e.preventDefault()
      e.target.blur()
      this.searchField.value = ''
      this.onFilterUpdated()
      this.controller(this.searchField)
    })

    this.searchField.addEventListener('keyup', (e) => {
      clearTimeout(this.isTyping)
      this.isTyping = setTimeout(this.onFilterUpdated.bind(this), 500)
    })

    this.form.addEventListener('submit', (e) => {
      e.preventDefault()
      return false
    })

    this.filterButtons.forEach((btn) => {
      btn.addEventListener('click', () => {
        this.filterContainer.classList.add('filters__container--visible')
        this.eventBus.emit('open-filters')
      })
    })

    this.eventBus.on('close-nav-list', () => {
      this.filterContainer.classList.remove('filters__container--visible')
    })

    this.closeButton.addEventListener('click', () => {
      this.filterContainer.classList.remove('filters__container--visible')
      this.eventBus.emit('closed-filters')
    })

    this.showButton.addEventListener('click', () => {
      this.filterContainer.classList.remove('filters__container--visible')
      this.eventBus.emit('closed-filters')
    })
  }

  onFiltersLoaded() {
    const ga = new GADataLayer()
    ga.push('optimize.activate', {})
  }

  onFilterUpdated() {
    this.controller.onFilterUpdated(this.form)
  }

  setCounters(counters, total) {
    // Clear all
    this.optLabels.forEach((elm) => {
      elm.innerHTML = elm.innerHTML.replace(/\(\d+\)/g, '(0)')
    })
    // Update values
    for (const field in counters) {
      for (const value in counters[field]) {
        const count = counters[field][value]
        const label = this.el.querySelector(
          `input[value="${value}"][name="${field}"] + .filter-form__checkbox-label .filter-form__checkbox-text`
        )
        if (label) {
          label.innerHTML = label.innerHTML.replace(/\(\d+\)/g, `(${count})`)
        }
      }
    }
    // Update button
    this.showButton.innerHTML = this.showButton.innerHTML.replace(/\d+/g, total)
  }

  clear = (value) => {
    const checkboxes = [...this.el.querySelectorAll('.filter-form__checkbox')]

    checkboxes.forEach((item) => {
      const hasSubOpts = item.classList.contains('filter-form__has-subopts')
      const input = item.querySelector('input')

      let uncheck = false

      if (value) {
        if (input.value === value) {
          uncheck = true
        }
      } else {
        // No value? Clear all
        uncheck = true
      }

      if (uncheck) {
        input.checked = false

        // Also uncheck sub options if available
        if (hasSubOpts) {
          const subOpts = [...item.querySelectorAll('.filter-form__subopts .filter-form__checkbox input')]
          subOpts.forEach((subOpt) => {
            subOpt.checked = false
          })
        }
      }
    })

    this.onFilterUpdated()
  }
}
