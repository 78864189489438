export default class Video {
  static name = "video"

  constructor (el, eventBus) {
    this.elm = el
    this.eventBus = eventBus
    this.videoElm = el.querySelector('.video__player')
    this.playButton = el.querySelector('.video__play-button')
    this.clickTarget = el.querySelector('.video__wrapper')

    if (window.ytVideos.length == 0) {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }
    window.ytVideos.push(this)
  }

  onPlayerReady () {
    this.playButton.classList.add('video__play-button--enabled')
    this.addEventHandlers()
  }

  addEventHandlers () {
    this.clickTarget.addEventListener('click', () => {
      this.player.playVideo()
      this.elm.classList.toggle('video--is-playing')
    })
    this.eventBus.on('closed-popover', () => {
      this.player.pauseVideo()
      this.elm.classList.remove('video--is-playing')
    })
  }
}

window.ytVideos = []

window['onYouTubeIframeAPIReady'] = () => {
  window.ytVideos.forEach((ytv) => {
    ytv.player = new window['YT'].Player(ytv.videoElm, {
      width: 1920,
      height: 1080,
      videoId: ytv.elm.dataset.videoId,
      host: `https://www.youtube.com`,
      playerVars: {
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 1,
        loop: 0,
        fs: 1,
        cc_load_policy: 1,
        iv_load_policy: 1,
        autohide: 1,
        playlist: ytv.elm.dataset.videoId,
        origin: window.location.href
      },
      events: {
        onReady: ytv.onPlayerReady.bind(ytv)
      }
    })
  })
}
