export default class ApplyUsps {
  static name = 'apply-usps'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.locationEl = this.el.querySelector('.apply__usp--location')

    this.eventBus.on('update-location', this.onUpdateLocation)
  }

  onUpdateLocation = (value) => {
    this.locationEl.innerHTML = value
  }
}
