export default class SalaryRaise {
  static name = 'salary-raise'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    this.init()
  }

  init = () => {
    this.titleEl = this.el.querySelector('.salary-raise__title')
    this.titleEl.addEventListener('click', this.toggleFoldout)

    const sliderInput = this.el.querySelector('.range-slider__input')

    if (sliderInput) {
      sliderInput.addEventListener('input', () => {
        this.updateSalary(parseInt(sliderInput.value))
      })
    }
  }

  updateSalary = (year) => {
    this.eventBus.emit('salary-years-update', year)
  }

  toggleFoldout = () => {
    this.el.classList.toggle('salary-raise--folded-out')
  }
}
