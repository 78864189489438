export default class Tooltip {
  static name = "tooltip"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el
    this.closeButton = this.el.querySelector('.tooltip__close')
    this.arrow = this.el.querySelector('.tooltip__arrow')

    this.eventBus.on('tooltip-open', this.open)

    if(this.closeButton) {
      this.closeButton.addEventListener('click', this.close)
    }
  }

  open = () => {
    this.el.classList.remove('tooltip--closed')

    this.eventBus.off('tooltip-open', this.open)
    this.eventBus.on('tooltip-close', this.close)

    setTimeout(() => {
      this.el.querySelector('.tooltip__content').style.height = this.el.getBoundingClientRect().height + 'px'
    }, 500)
  }

  close = (e) => {
    if (e) {
      e.stopPropagation()
    }

    this.eventBus.on('tooltip-open', this.open)
    this.eventBus.off('tooltip-close', this.close)

    this.el.classList.add('tooltip--closed')
  }
}
