export default class LanguageSwitch {
  static name = "language-switch"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el

    const switchButton = this.el.querySelector('.language-switch-simple__extended-link')
    if (switchButton) {
      switchButton.addEventListener("click", this.onClick)
    }

    this.eventBus.on('toggle-language-switch', this.toggle)
    this.eventBus.on('close-language-switch', this.close)

    document.addEventListener('click', this.close)
    this.el.addEventListener('click', (e) => { e.stopPropagation() })
  }

  onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.el.classList.add('language-switch--show-extended')
  }

  toggle = () => {
    if (this.el.classList.contains('language-switch--visible')) {
      this.close();
    } else {
      this.open();
    }
  }

  open = () => {
    this.el.classList.add('language-switch--visible')
    this.eventBus.emit('opened-language-switch')
  }

  close = () => {
    this.el.classList.remove('language-switch--visible')
    this.eventBus.emit('closed-language-switch')
    setTimeout(this.closed, 300)
  }

  closed = () => {
    this.el.classList.remove('language-switch--show-extended')

  }
}
