import easyScroll from 'easy-scroll'

export default class CtaButton {
  static name = 'cta-button'

  constructor(el) {
    this.el = el
    this.hash = this.el.hash
    this.anchorBlock = this.hash ? document.querySelector(this.hash) : null

    if (this.hash && this.anchorBlock) {
      this.el.addEventListener('click', this.scrollToEl)
    }
  }

  scrollToEl = (event) => {
    event.preventDefault()
    const delta = Math.round(this.anchorBlock.getBoundingClientRect().top)

    easyScroll({
      'scrollableDomEle': window,
      'direction': 'bottom',
      'duration': 1000,
      'easingPreset': 'easeInQuad',
      'scrollAmount': delta
    })
  }
}
