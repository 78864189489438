export default class AccountMenuButton {
  static name = "account-menu-button"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el

    this.eventBus.on('opened-account-menu', this.open)
    this.eventBus.on('closed-account-menu', this.close)
  }

  open = () => {
    this.el.classList.add('account-menu-button--active')
  }

  close = () => {
    this.el.classList.remove('account-menu-button--active')
  }
}
