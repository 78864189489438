import Game from './game'

export default class kc {
  constructor () {
    this.allowedKeys = { 37: 'left', 38: 'up', 39: 'right', 40: 'down', 65: 'a', 66: 'b', 27: 'esc' }
    this.code = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a']
    this.idx = 0
    this.game = new Game()
    document.addEventListener('keydown', function (e) {
      const key = this.allowedKeys[e.keyCode]
      const next = this.code[this.idx]
      if (key === next) {
        this.idx++
        if (this.idx === this.code.length) {
          this.activate()
          this.idx = 0
        }
      } else {
        this.idx = 0
      }
      if (key === 'esc') {
        this.deactivte()
      }
    }.bind(this))
  }

  activate () {
    this.game.start()
  }

  deactivte () {
    this.game.stop()
  }
}
