import {getDistance} from 'geolib';
import GADataLayer from '../../core/libs/ga-data-layer'

export default class LocationPopover {
  static name = 'location-popover'

  constructor(el, eventBus) {
    this.el = el
    this.ga = new GADataLayer()
    this.baseUrl = this.el.dataset.baseUrl
    this.presetLocation = this.el.dataset.presetLocation
    this.eventBus = eventBus
    this.locationList = this.el.querySelector('.location-card-list')
    this.locations = [...this.el.querySelectorAll('input[type="radio"]')]
    this.submitButton = this.el.querySelector('button[type="submit"]')
    this.presetArea = false

    if (this.el.hasAttribute("data-preset-area")) {
      this.presetArea = JSON.parse(this.el.dataset.presetArea)
    }

    this.setEventHandlers()
    this.initGeoData()
  }

  setEventHandlers() {
    // If we don't have a slug reset the form to prevent the browser from caching previous choices
    this.eventBus.on('opening-popover', () => {
      if (!this.slug) {
        this.el.reset()
      }
    })

    this.el.addEventListener('submit', (e) => {
      this.onSubmit(e)
    })
    this.locations.forEach((location) => {
      location.addEventListener('change', () => {
        this.onLocationChecked(location)
      })
      if (location.checked) {
        this.onLocationChecked(location)
      }
    })
  }

  initGeoData() {
    if (!navigator.geolocation || this.locationList === null || this.locationList.children.length <= 1) {
      sessionStorage.setItem('geo-preferences', JSON.stringify({enabled: false, coords: null}))
      return
    }

    const geoPreferences = JSON.parse(sessionStorage.getItem('geo-preferences'))

    if (geoPreferences && !geoPreferences.enabled && this.presetArea) {
      return
    }

    if(geoPreferences && geoPreferences.enabled && this.presetArea) {
      this.setListOrder(geoPreferences.coords)
      return
    }

    if (geoPreferences && geoPreferences.enabled) {
      this.setListOrder(geoPreferences.coords)
      this.eventBus.emit('popover-open', 'location-popover')
      return
    }

    this.eventBus.emit('tooltip-open')
    this.getGeoData()
  }

  getGeoData() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setListOrder(position.coords)
        this.eventBus.emit('popover-open', 'location-popover')
        this.eventBus.emit('tooltip-close')

        sessionStorage.setItem('geo-preferences', JSON.stringify({
          enabled: true,
          coords: { latitude: position.coords.latitude, longitude: position.coords.longitude }
        }))
      },
      () => {
        this.eventBus.emit('tooltip-close')
        sessionStorage.setItem('geo-preferences', JSON.stringify({enabled: false, coords: null}))
      }
    )
  }

  onLocationChecked(locationEl) {
    this.slug = locationEl.value
    this.updateSubmitButton()
  }

  updateSubmitButton() {
    this.submitButton.disabled = !this.slug || this.slug === this.presetLocation
  }

  onSubmit(e) {
    e.preventDefault()
    this.ga.push('apply_for_job', { location: e.target.dataset.eventName })
    console.log(e.target.dataset.eventName)
    const url = `${this.baseUrl}${this.slug}`
    window.location = url
  }

  setListOrder(coords) {
    const userLocation = {
      latitude: coords.latitude,
      longitude: coords.longitude
    }

    let listItems = []

    Array.from(this.locationList.children).forEach(item => {
      const location = {
        latitude: parseFloat(item.dataset.lat),
        longitude: parseFloat(item.dataset.long)
      }

      const distance = getDistance(userLocation, location)

      listItems.push({item, distance})
    })

    listItems.sort(this.sortByDistance)
    this.locationList.innerHTML = ''

    listItems.forEach((item, index) => {
      const listItem = item.item

      if (!isNaN(item.distance)) {
        const distanceIndicator = document.createElement('span');
        distanceIndicator.innerText = `${Math.round(item.distance / 1000)}km`
        distanceIndicator.classList.add('location-card__distance')

        listItem.querySelector('.location-card__title').appendChild(distanceIndicator)
      }
      this.locationList.append(listItem)
    })

    setTimeout(() => {
      const locationEl = this.locationList.querySelectorAll('.location-card__radio')[0]
      locationEl.checked = true
      this.slug = locationEl.value
      this.updateSubmitButton()
    }, 500)
  }

  sortByDistance(a, b) {
    if (isNaN(a.distance)) {
      return 1
    }

    if (isNaN(b.distance)) {
      return -1
    }

    return (a.distance > b.distance) ? 1 : -1
  }
}
