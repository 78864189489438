export default class ApplyTimeline {
  static name = "apply-timeline"

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.eventBus.on("planner-booked", this.onPlannerBooked)
  }

  onPlannerBooked = (values) => {
    this.eventBus.emit('popover-close')
    const plannerStep = this.el.querySelector('[data-step-type="planner"]')
    if (plannerStep) {
      plannerStep.classList.remove("apply-timeline-step--active")
      plannerStep.classList.add("apply-timeline-step--completed")

      plannerStep.querySelector(".apply-timeline-step__number").innerText = ""
      plannerStep.querySelector(".apply-timeline-step__title").innerText = plannerStep.dataset.completedTitle
      plannerStep.querySelector(".apply-timeline-step__text").innerText = plannerStep.dataset.completedText

      plannerStep.querySelector(".apply-timeline-step__button").style.display = "none"

      const nextIndex = parseInt(plannerStep.dataset.index) + 1
      const nextStep = this.el.querySelector(`.apply-timeline-step[data-index="${nextIndex}"]`)
      nextStep.classList.add("apply-timeline-step--active")
    }

    const plannerBar = document.querySelector(".sticky-bar--planner")
    if (plannerBar) {
      plannerBar.remove()
    }
  }
}
