import GADataLayer from '../../core/libs/ga-data-layer'

export default class Search {
  static name = "search"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el
    this.input = this.el.querySelector('.search__input-text, .search-bar__input')

    const expandButton = this.el.querySelector('.search__expand-button')
    if (expandButton) {
      expandButton.addEventListener('click', this.open)
    }
    const closeButton = this.el.querySelector('.search__input-close')
    if (closeButton) {
      closeButton.addEventListener('click', this.close)
    }

    this.el.addEventListener('submit', (e) => {
      const ga = new GADataLayer()
      ga.push('headerSearch', { query: this.input.value })
    })



    document.addEventListener('keyup', (e) => {
      if(e.key === 'Escape') {
        this.eventBus.emit('close-search')
      }
    })
  }

  open = () => {
    this.eventBus.emit('opened-search')
    this.el.classList.add("search--expanded")
    this.input.focus()

    this.eventBus.on('close-search', this.close)
  }

  close = () => {
    this.eventBus.emit('closed-search')
    this.el.classList.remove("search--expanded")

    this.eventBus.off('close-search', this.close)
  }

}
