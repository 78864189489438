export default class Notification {
  static name = "notification"

  constructor (el, eventBus) {
    this.eventBus = eventBus
    this.el = el
    this.isActive = this.el.dataset.default && this.el.dataset.default === 'active' ? true : false
    this.dotEl = this.el.querySelector('.notification')
    this.extraEl = this.el.querySelector(this.el.dataset.extraEl)

    if(!this.dotEl) {
      this.dotEl = this.el
    }

    this.el.addEventListener('click', this.handleClick)

    this.el.addEventListener('mouseenter', () => {
      if(this.timeout) {
        clearTimeout(this.timeout)
      }
    })

    this.el.addEventListener('mouseout', () => {
      this.timeout = setTimeout(() => {
        this.handleHover()
      }, 1000)
    })

    this.updateEl(this.dotEl)
  }

  updateEl = (el) => {
    if(this.isActive) {
      el.style.display = 'block'
    } else {
      el.style.display = 'none'
    }
  }

  handleHover = () => {
    if(window.innerWidth > 767) {
      this.handleClick()
    }
  }

  handleClick = () => {
    this.isActive = false
    this.updateEl(this.dotEl)

    if(this.extraEl) {
      this.updateEl(this.extraEl)
    }
  }
}
