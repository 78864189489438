export default class ReadMore {
  static name = "readmore"

  constructor (el, eventBus) {
    this.eventBus = eventBus
    this.el = el

    this.init()
  }

  init () {
    this.el.addEventListener('click', () => {
      const target = document.querySelector('.gallery-block') || document.querySelector('.page-content')
      if (target) {
        target.scrollIntoView({behavior: 'smooth'})
      }
    })
  }
}
