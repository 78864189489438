export default class CalendarPopOver {
  constructor (eventBus) {
    this.eventBus = eventBus
    this.elements = document.querySelectorAll('[data-controller="calendar-popover"]')
    this.popover = document.querySelector('#interactive-calendar-popover')

    if(this.popover && this.elements) {
      this.title = this.popover.querySelector('.popover__title')
      this.time = this.popover.querySelector('.interactive-calendar__metadata-item--time')
      this.location = this.popover.querySelector('.interactive-calendar__metadata-item--location')
      this.description = this.popover.querySelector('.interactive-calendar__item-content')

      this.buttonEl = this.popover.querySelector('.popover__button')
      this.backdropEl = this.popover.querySelector('.popover__backdrop')

      this.init()
    }
  }

  addEventListeners = () => {
    if (this.buttonEl) {
      this.buttonEl.addEventListener('click', this.close)
    }

    if (this.backdropEl) {
      this.backdropEl.addEventListener('click', this.close)
    }

    document.addEventListener('keyup', (e) => {
      if (e.which === 27) {
        this.close()
      }
    })
  }

  init = () => {
    this.elements.forEach((el) => {
      if (el.dataset.value) {
        el.addEventListener('click', this.open)
      }
    })

    this.addEventListeners()
    document.body.appendChild(this.popover)
  }

  open = (e) => {
    const target = e.target.closest('[data-controller="calendar-popover"]')
    const value = JSON.parse(target.dataset.value)

    this.title.innerText = value.title
    this.time.innerText = value.time
    this.location.innerText = value.location
    this.description.innerHTML = value.description

    this.popover.classList.add('popover--open')
    document.body.classList.add('no-overflow')

    this.eventBus.emit('opened-popover')
  }

  close = () => {
    this.popover.classList.remove('popover--open')
    document.body.classList.remove('no-overflow')
  }
}
