import easyScroll from 'easy-scroll'

export default class SalaryButton {
  static name = 'salary-button'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    this.target = document.querySelector(this.el.dataset.target)
    this.el.addEventListener('click', this.scrollToEl)
  }

  scrollToEl = () => {
    const delta = Math.round(this.target.getBoundingClientRect().top) - 100
    easyScroll({
      'scrollableDomEle': window,
      'direction': 'bottom',
      'duration': 1000,
      'easingPreset': 'easeInQuad',
      'scrollAmount': delta
    })

    this.eventBus.emit('set-expandable', true)
  }
}
