import { bareCookies } from '../../core/libs/utils'
import GADataLayer from '../../core/libs/ga-data-layer'

const basicCookieSettings = {
  basic: 'basic',
  all: 'all',
}

const extendCookieSettings = {
  basic: {
    'functional': true,
    'analytical': false,
    'marketing': false
  },
  all: {
    'functional': true,
    'analytical': true,
    'marketing': true
  },
}

export default class CookieBar {
  static name = 'cookie-bar'

  constructor (el) {
    this.el = el
    this.datalayer = new GADataLayer()
    this.extendedCookieSettings = (this.el.dataset.extendedCookieSettings === 'True')
    this.cookieSettings = this.extendedCookieSettings ? extendCookieSettings : basicCookieSettings
    this.cookieOptions = this.extendedCookieSettings ? [...this.el.querySelectorAll('.cookie-bar__option-input')] : null

    this.acceptAll = this.el.querySelector('.cookie-bar__accept-all')
    this.acceptSelected = this.el.querySelector('.cookie-bar__accept-selected')
    this.changeButton = this.el.querySelector('.cookie-bar__change')
    this.decline = this.el.querySelector('.cookie-bar__decline')
    this.declineVariant = this.el.querySelector('.cookie-bar__decline-variant')
    this.readmore = this.el.querySelector('.cookie-bar__readmore')

    if (this.acceptAll) {
      this.acceptAll.addEventListener('click', (e) => {
        e.preventDefault()
        this.setCookie('all')
        this.getCookieSettings('all')
        document.location.reload()
      })
    }

    if (this.acceptSelected) {
      this.acceptSelected.addEventListener('click', (e) => {
        e.preventDefault()
        this.getCookieSettings()
        document.location.reload()
      })
    }

    if (this.changeButton) {
      this.changeButton.addEventListener('click', (e) => {
        e.preventDefault()
        this.el.classList.add("cookie-bar--options-visible")
      })
    }

    if (this.decline) {
      this.decline.addEventListener('click', (e) => {
        e.preventDefault()
        this.setCookie('basic')
        this.getCookieSettings('basic')
        document.location.reload()
      })
    }
    if (this.declineVariant) {
      this.declineVariant.addEventListener('click', (e) => {
        e.preventDefault()
        this.setCookie('basic')
        this.getCookieSettings('basic')
        document.location.reload()
      })
    }

    if (this.readmore) {
      this.readmore.addEventListener('click', (e) => {
        this.setCookie('basic')
        this.getCookieSettings('basic')
      })
    }

    this.init()
  }

  init () {
    const cookieConsent = bareCookies.get(this.el.dataset.cookieName)
    const cookieDate = bareCookies.get('CookieBarDate')

    if (cookieConsent === undefined || cookieDate === undefined) {
      this.el.classList.add('cookie-bar--visible')
    }

    if(cookieConsent === 'all') {
      this.datalayer.setConsentModeAll()
    }

    if(cookieConsent === 'basic') {
      this.datalayer.setConsentModeAnalytics()
    }
  }

  setCookie (val) {
    const options = {
      expires: 365,
      secure: location.protocol === 'https:',
      sameSite: 'lax'
    }
    const date = new Date()

    bareCookies.set(this.el.dataset.cookieName, val, options)
    bareCookies.set('CookieBarDate', date.toISOString(), options)

    this.el.classList.remove('cookie-bar--visible')
  }

  getCookieSettings = (value = null) => {
    let cookieSettings = this.cookieSettings[value]

    if (cookieSettings == undefined) {
      cookieSettings = this.getPreferences()
    }

    this.setCookie(cookieSettings)
  }

  getPreferences = () => {
    const settings = {}
    this.cookieOptions.forEach(option => {
      settings[option.value] = option.checked
    })

    return settings
  }
}
