export default class AccountMenu {
  static name = "account-menu"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el

    this.eventBus.on('toggle-account-menu', this.toggle)
    this.eventBus.on('close-account-menu', this.close)

    document.addEventListener('click', this.close)
    this.el.addEventListener('click', (e) => { e.stopPropagation() })
  }


  toggle = () => {
    if (this.el.classList.contains('account-menu--visible')) {
      this.close();
    } else {
      this.open();
    }
  }

  open = () => {
    this.el.classList.add('account-menu--visible')
    this.eventBus.emit('opened-account-menu')
  }

  close = () => {
    this.el.classList.remove('account-menu--visible')
    this.eventBus.emit('closed-account-menu')
  }
}
