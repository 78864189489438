import intlTelInput from 'intl-tel-input'
// import 'intl-tel-input/build/js/utils.js';

// See:
// https://intl-tel-input.com/
// https://github.com/jackocnr/intl-tel-input

export default class IntlPhone {
  static name = 'intl-phone'

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.input = this.el.querySelector('input')
    this.country = document.querySelector('input[name="phone_selected_country"]')
    this.countryCode = document.querySelector('input[name="phone_selected_country_code"]')
    this.lang = document.querySelector('html').getAttribute('lang')
    this.setCountry = document.querySelector('[name="country"]').content

    // Set default options based on country
    this.defaultIntlTelOptions = {
      preferredCountries: [this.setCountry], // Will show up on top of the dropdown list
      separateDialCode: false,
    }

    // Initialize international phone input field
    this.iti = intlTelInput(this.input, this.defaultIntlTelOptions)

    // Set the current language of the input field
    this.iti.setCountry(this.setCountry)

    // Important to call setValue on init. Otherwise, we don't have a default country code.
    this.setValue()

    // First load the intltel utils. Then init.
    window.intlTelInputGlobals.loadUtils('/static/js/utils.js').then(this.init)
  }

  init = () => {
    if (this.input.value) {
      // Ask to validate field in case we have a prefilled value from server.
      this.eventBus.emit('validate-field', { target: this.input })
    }
    this.input.addEventListener('countrychange', this.onCountryChange)
  }

  setValue() {
    const data = this.iti.getSelectedCountryData()
    this.country.value = this.formatCountryName(data.name)
    this.countryCode.value = data.iso2
  }

  onCountryChange = () => {
    this.input.value = this.iti.getNumber()
    this.setValue()
  }

  formatCountryName = (name) => {
    return name.replace(/ *\([^)]*\)$ */g, '')
  }
}
