export default class VacancyMapWindow {
  static name = 'vacancy-map-window'

  constructor(eventBus, map, location) {
    this.location = location
    this.map = map
    this.eventBus = eventBus
    this.init()
  }

  init = () => {
    this.infowindow = new google.maps.InfoWindow({
      content: this.location.window,
      maxWidth: 450,
      pixelOffset: new google.maps.Size(0, 60)
    })

    this.location.marker.addListener('click', () => {
      this.eventBus.emit('close-map-windows', { except: this.location.key })
      this.eventBus.emit('show-map-overlay')
      this.infowindow.open(this.map, this.location.marker)
    })

    this.currentSlide = 1
    this.maxSlides = this.location.totalVacancies

    google.maps.event.addListener(this.infowindow, 'domready', () => {
      if (this.maxSlides > 1) {
        this.updateSlides()
      }
      this.addListeners()
    })
  }

  getElements = () => {
    const mapWindow = document.querySelector(`[data-key="${this.location.key}"]`)
    if (!mapWindow) {
      return
    }
    return {
      closeButton: mapWindow.querySelector('.vacancy-map-window__controls-close'),
      backwardButton: mapWindow.querySelector('.vacancy-map-window__controls-backward'),
      forwardButton: mapWindow.querySelector('.vacancy-map-window__controls-forward'),
      currentSlide: mapWindow.querySelector('.vacancy-map-window__controls-current span'),
      slides: mapWindow.querySelectorAll('.vacancy-map-window__vacancy')
    }
  }

  addListeners = () => {
    const elements = this.getElements()
    if (elements) {
      elements.closeButton.addEventListener('click', this.onCloseClick)
      if (this.maxSlides > 1) {
        elements.backwardButton.addEventListener('click', this.onBackwardClick)
        elements.forwardButton.addEventListener('click', this.onForwardClick)
      }
    }
  }

  removeListeners = () => {
    const elements = this.getElements()
    if (elements) {
      elements.closeButton.removeEventListener('click', this.onCloseClick)
      if (this.maxSlides > 1) {
        elements.backwardButton.removeEventListener('click', this.onBackwardClick)
        elements.forwardButton.removeEventListener('click', this.onForwardClick)
      }
    }
  }

  onCloseClick = () => {
    this.close()
  }

  onBackwardClick = (e) => {
    if (this.currentSlide === 1) {
      return
    }
    this.currentSlide -= 1
    this.updateSlides()

    e.preventDefault()
  }

  onForwardClick = (e) => {
    if (this.currentSlide === this.maxSlides) {
      return
    }
    this.currentSlide += 1
    this.updateSlides()

    e.preventDefault()
  }

  updateSlides = () => {
    const elements = this.getElements()

    // Update slide counter
    elements.currentSlide.innerHTML = '' + this.currentSlide

    // Update back button
    if (this.currentSlide === 1) {
      elements.backwardButton.classList.add('vacancy-map-window__control--disabled')
    } else {
      elements.backwardButton.classList.remove('vacancy-map-window__control--disabled')
    }

    // Update forward button
    if (this.currentSlide === this.maxSlides) {
      elements.forwardButton.classList.add('vacancy-map-window__control--disabled')
    } else {
      elements.forwardButton.classList.remove('vacancy-map-window__control--disabled')
    }

    // Update slides
    for (let i = 0; i < this.maxSlides; i++) {
      const slideIndex = i + 1
      if (slideIndex === this.currentSlide) {
        elements.slides[i].classList.remove('vacancy-map-window__vacancy--hidden')
      } else {
        elements.slides[i].classList.add('vacancy-map-window__vacancy--hidden')
      }
    }

  }

  update = (location) => {
    this.currentSlide = 1
    this.maxSlides = location.totalVacancies

    this.location = location
    this.infowindow.setContent(this.location.window)
  }

  close = () => {
    this.currentSlide = 1
    this.removeListeners()
    this.infowindow.close()
    this.eventBus.emit('hide-map-overlay')
  }
}
