import easyScroll from 'easy-scroll'

export default class ApplyBar {
  static name = 'contact-button'

  constructor(el) {
    this.el = el
    this.element = document.querySelector(this.el.dataset.element)
    this.header = document.querySelector('.vacancy-header ')

    this.el.querySelector('.contact-button__image')
      .addEventListener('click', this.scrollToElement)

    if (this.header) {
      this.initIntersectionObserver()
    }

    if (this.element) {
      this.initIntersectionObserver()
    }
  }

  initIntersectionObserver() {
    const observer = new IntersectionObserver((entries) => {

      let hideContactButton = false

      entries.forEach(entry => {
        if (entry.intersectionRatio > 0.5) {
          hideContactButton = true
        }
      })

      if (hideContactButton) {
        this.el.classList.add('contact-button--hidden')
      } else {
        this.el.classList.remove('contact-button--hidden')
      }

    }, { threshold: 0.5 })
    observer.observe(this.element)
    observer.observe(this.header)
  }

  scrollToElement = () => {
    const delta = Math.round(this.element.getBoundingClientRect().bottom - window.innerHeight / 2)

    easyScroll({
      'scrollableDomEle': window,
      'direction': 'bottom',
      'duration': 1000,
      'easingPreset': 'easeInQuad',
      'scrollAmount': delta
    })
  }
}
