export default class VacancyTextFoldout {
  static name = 'vacancy-text-foldout'

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    this.el.querySelector('.vacancy-text__title')
      .addEventListener('click', () => {
        this.el.classList.toggle('vacancy-text--open')
      })
  }
}
