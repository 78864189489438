import serialize from 'form-serialize'
import GADataLayer from '../../core/libs/ga-data-layer'
import Filters from './filters'
import Results from './results'
import ActiveFilters from './active-filters'

const defaultFetchOptions = {
  cache: 'no-cache',
  credentials: 'same-origin',
  mode: 'cors',
  redirect: 'follow',
  referrer: 'no-referrer'
}

export default class Vacancies {
  static name = 'vacancies'

  loadingClass = 'vacancies--loading'
  emptyClass = 'vacancies--empty'
  errorClass = 'vacancies--error'
  running = null

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    // Note: order is important
    this.activeFilters = new ActiveFilters(this.el.querySelector(".active-filters"), this, eventBus)
    this.results = new Results(this.el.querySelector(".results"), this, eventBus)
    this.filters = new Filters(this.el.querySelector(".filters"), this, eventBus)
  }

  formDataFromForm(form) {
    const formData = new FormData()
    const data = serialize(form, { hash: true })
    for (const key in data) {
      formData.append(key, data[key])
    }
    formData.append('sourceUrl', this.el.dataset.source)
    formData.append('initialCount', this.el.dataset.initialCount)

    return formData
  }

  urlFromForm(form) {

    // Transform form fields to urlParams
    const urlParams = serialize(form, {
      hash: false,
      serializer: this.serialize
    })

    // Check for existing url params (only in whitelist)
    let additionalParams = ""
    const paramsWhitelist = [
      'cmt',
      'utm_medium',
      'utm_source',
      'utm_content',
      'utm_campaign',
      'gclid',
      'utm_term'
    ]

    if (window.location.search) {
      const params = window.location.search.substring(1)
      for (const param of params.split("&")){
        const parts = param.split("=")
        if (parts.length === 2) {
          const key = parts[0]
          const value = parts[1]
          if (paramsWhitelist.includes(key)) {
            additionalParams = `${additionalParams}&${key}=${value}`
          }
        }
      }
    }

    if (urlParams) {
      return `${this.el.dataset.source}?${urlParams}${additionalParams}`
    } else if (additionalParams) {
      additionalParams = additionalParams.substring(1) // Remove first &
      return `${this.el.dataset.source}?${additionalParams}`
    } else {
      return `${this.el.dataset.source}`
    }
  }

  onFilterUpdated = (form) => {
    const formData = this.formDataFromForm(form)
    const updatedUrl = this.urlFromForm(form)

    window.history.replaceState(window.history.state, window.title, updatedUrl)

    this.activeFilters.update(form)
    this.updateResults(form.action, form.method, formData)
  }

  clearFilter = (value) => {
    this.filters.clear(value)
  }

  clearFilters = () => {
    this.filters.clear()
  }

  updateResults(action, method, formData) {
    this.resetResults()
    this.el.classList.add(this.loadingClass)
    if (this.running) {
      this.running.abort()
    }
    this.running = this.fetchData(action, method, formData)
  }

  fetchData(action, method, formData) {
    const controller = new AbortController();
    const { signal } = controller;
    const opts = Object.assign({ signal }, defaultFetchOptions, {
      method: method,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData
    })
    return {
      abort: () => controller.abort(),
      ready: fetch(action, opts)
        .then(response => response.json())
        .then((response) => {
          this.handleResponse(response)
        })
        .catch(error => this.handleError(error))
    }
  }

  handleResponse(response) {
    this.queueLength--
    if (this.queueLength > 0) return

    this.el.classList.remove(this.loadingClass)
    const items = response.items
    const banners = response.banners
    const counters = response.counters
    const mapItems = response.mapItems

    if (items.length === 0) {
      this.el.classList.add(this.emptyClass)
    } else {
      this.filters.setCounters(counters, response.items.length)
    }

    this.results.update(items, banners, mapItems)

    this.eventBus.emit('resetLoadMore')
  }

  handleError(error) {
    if (error.name !== 'AbortError') {
      this.el.classList.remove(this.loadingClass)
      this.el.classList.add(this.errorClass)
    }
  }

  resetResults() {
    if (this.el.classList.contains(this.errorClass)) {
      this.el.classList.remove(this.errorClass)
    }
    if (this.el.classList.contains(this.emptyClass)) {
      this.el.classList.remove(this.emptyClass)
    }
  }

  sendGAEvent(event) {
    let GAEvent = undefined
    if (event.type === 'checkbox') {
      GAEvent = event.checked ? 'filterUsed' : 'filterDeselect'
    }
    if (event.type === 'text' && event.value.length > 0) {
      GAEvent = 'filterSearch'
    }

    if (GAEvent) {
      const ga = new GADataLayer()
      ga.push(GAEvent, {
        filterAction: event.name,
        filterLabel: event.value
      })
    }
  }

  // urlform encoding serializer sample taken from node_modules/form-serialize/index.js:250 as a custom serializer
  serialize(result, key, value) {
    const excludeList = [
      'csrfmiddlewaretoken',
      'source-url',
      'initial-count',
      'is_starter_job',
      'is_side_job',
      'is_relocation',
      'is_internship',
      'is_leadership',
      'is_summer_job',
    ]
    if (excludeList.indexOf(key) >= 0) return result

    // encode newlines as \r\n cause the html spec says so
    value = value.replace(/(\r)?\n/g, '\r\n')
    value = encodeURIComponent(value)

    // spaces should be '+' rather than '%20'.
    value = value.replace(/%20/g, '+')
    return result + (result ? '&' : '') + encodeURIComponent(key) + '=' + value
  }
}
