export default class LoadMore {
  static name = 'loadmore'

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.button = this.el.querySelector(this.el.dataset.button)
    this.crossLink = this.el.querySelector('.results__cross-link')
    this.banner = this.el.querySelector('.results__banner')
    this.buttonTimeout = 0
    this.idx = this.el.dataset.page
    this.init()
  }

  init() {
    if (this.button) {
      this.button.addEventListener('click', this.loadMore.bind(this))
    }
    if(JSON.parse(this.el.dataset.ajax)) {
      this.eventBus.on('resetLoadMore', this.reset.bind(this))
    } else {
      this.reset()
    }
  }

  reset() {
    this.idx = 1
    this.updateUrl()
    const hidden = [...this.el.querySelectorAll(this.el.dataset.hidden)]
    this.updateButton(hidden.length)

    if (hidden.length === 0) {
      this.showCrossLink()
      this.showBanner()
    } else {
      this.hideCrossLink()
      this.hideBanner()
    }
  }

  loadMore() {
    this.button.blur()
    const hidden = [...this.el.querySelectorAll(this.el.dataset.hidden)]

    let totalShownItems = 0

    hidden.forEach((item, idx) => {
      if (idx < 10) {
        setTimeout(() => {
          item.classList.remove(this.el.dataset.hidden.substring(1))
        }, 50 * idx)
        totalShownItems += 1
      }
    })

    const remaining = hidden.length - 10
    this.updateButton(remaining)
    this.idx++

    if (remaining <= 0) {
      this.showCrossLink(totalShownItems)
      this.showBanner(totalShownItems)
    }

    this.updateUrl()
  }

  updateUrl() {
    // Less than page 2 is not needed.
    if (this.idx < 2) {
      return
    }

    let pageUrl
    if (window.location.href.indexOf('page=') > 0) {
      pageUrl = window.location.href.replace(/page=\d+/g, `page=${this.idx}`)
    } else {
      pageUrl = window.location.href + `${window.location.search.length ? '&' : '?'}page=${this.idx}`
    }
    window.history.replaceState(window.history.state, window.title, pageUrl)
  }

  updateButton(remaining) {
    clearTimeout(this.buttonTimeout)
    if (remaining <= 0) {
      this.button.style.display = 'none'
    } else {
      const count = (remaining > 10) ? 10 : remaining
      this.buttonTimeout = setTimeout(() => {
        this.button.style.display = 'block'
        this.button.innerText = this.button.innerText.replace(/\d+/g, count)
      }, 150)
    }
  }

  hideCrossLink() {
    if (this.crossLink) {
      this.crossLink.classList.remove('results__cross-link--anim')
      this.crossLink.classList.add('results__cross-link--hidden')
    }
  }

  showCrossLink(totalShownItems) {
    if (!this.crossLink) {
      return
    }
    totalShownItems += 1

    if (totalShownItems > 0) {
      setTimeout(() => {
        this.crossLink.classList.add('results__cross-link--anim')
        this.crossLink.classList.remove('results__cross-link--hidden')
      }, 50 * totalShownItems)
    } else {
       this.crossLink.classList.remove('results__cross-link--hidden')
    }
  }

  hideBanner() {
    if (this.banner) {
      this.banner.classList.add('results__banner--hidden')
    }
  }

  showBanner(totalShownItems) {
    if (!this.banner) {
      return
    }

    totalShownItems += 1

    if (totalShownItems > 0) {
      setTimeout(() => {
        this.banner.classList.remove('results__banner--hidden')
      }, 50 * totalShownItems)
    } else {
       this.banner.classList.remove('results__banner--hidden')
    }
  }
}
