const consentMode = {
  basic: {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied'
  },
  all: {
    'ad_storage': 'granted',
    'ad_user_data': 'granted',
    'ad_personalization': 'granted',
    'analytics_storage': 'granted'
  },
  analytics: {
    'analytics_storage': 'granted'
  }
}

export default class GADataLayer {
  constructor () {
    this.dl = window.dataLayer = window.dataLayer || []
  }

  push (event, data) {
    const node = Object.assign({ event: event }, data)
    this.dl.push(node)
  }

  gtag () {
    this.dl.push(arguments)
  }

  setConsentModeBasic () {
    this.gtag('consent', 'update', consentMode.basic)
  }

  setConsentModeAll () {
    this.gtag('consent', 'update', consentMode.all)
  }

  setConsentModeAnalytics () {
    this.gtag('consent', 'update', consentMode.analytics)
  }
}
