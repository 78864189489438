export default class ShareButton {
  static name = 'share-button'

  constructor(el, eventBus) {
    this.eventBus = eventBus

    this.el = el
    this.mobileShareText = this.el.dataset.mobileShareText

    this.button = this.el.querySelector('button')
    this.button.addEventListener('click', this.onClick)

    this.el.querySelectorAll(".share-options__icon").forEach(el => {
      el.addEventListener("click", (e) => {
        if (el.dataset.do) {
          this.shareButtonClicked(e, el)
        }
      })
    })
  }

  shareButtonClicked = (e, el) => {
    e.preventDefault()
    if (el.dataset.do === "open-in-popup") {
      this.popupWindow(el.href)
    }
    if (el.dataset.do === "copy-to-clipboard") {
      this.copyToClipboard(el.dataset.url)
    }
  }

  popupWindow = (url) => {
    const width = 600
    const height = 500

    const y = window.top.outerHeight / 2.5 + window.top.screenY - ( height / 2)
    const x = window.top.outerWidth / 2 + window.top.screenX - ( width / 2)

    return window.open(url, "social_share", `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`)
  }

  copyToClipboard = (text) => {
    const input = document.createElement("input");
    input.value = text;
    document.body.appendChild(input);
    input.select();

    document.execCommand("copy");
    document.body.removeChild(input);

    this.el.classList.add("share-button--clipboard-copied")
    setTimeout(() => {
      this.el.classList.remove("share-button--clipboard-copied")
    }, 1000)
  }

  onClick = (e) => {
    e.preventDefault()

    const mobileShare = {
      text: this.mobileShareText
    }

    // Guess if we are on desktop based on window width in order to prevent
    // Safari from showing the share interface on desktop
    const desktop = window.innerWidth > 1023

    if (navigator.canShare && navigator.canShare(mobileShare) && !desktop) {
      navigator.share(mobileShare)
    }
  }
}
