export default class ResultsButtonRow {
  static name = 'results-button-row'

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    // create intersection observer for floating filter button
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio !== 1 && entry.boundingClientRect.y > 0) {
          this.eventBus.emit('floating-bar', { float: true })
        } else {
          this.eventBus.emit('floating-bar', { float: false })
        }
      })
    }, { threshold: 1 })
    observer.observe(this.el)
  }
}
