export default class PopOver {
  static name = 'popover'

  constructor (el, eventBus) {
    this.eventBus = eventBus
    this.el = el

    if (this.el.dataset.target) {
      this.popover = document.getElementById(this.el.dataset.target)
      this.buttonEl = this.popover.querySelector('.popover__button')
      this.backdropEl = this.popover.querySelector('.popover__backdrop')
      this.init()
    }

    this.isOpen = false
  }

  addEventListeners = () => {
    if (this.buttonEl) {
      this.buttonEl.addEventListener('click', this.close)
    }

    if (this.backdropEl) {
      this.backdropEl.addEventListener('click', (e) => {
        if (this.isOpen) this.close()
      })
    }

    document.addEventListener('keyup', (e) => {
      if (e.which === 27) {
        if (this.isOpen) this.close()
      }
    })

    this.eventBus.on('form-submitted', this.close)
    this.eventBus.on('popover-close', this.close)
  }

  removeEventListeners = () => {
    if (this.buttonEl) {
      this.buttonEl.removeEventListener('click', this.close)
    }

    if (this.backdropEl) {
      this.backdropEl.removeEventListener('click', (e) => {
        if (this.isOpen) this.close()
      })
    }

    document.removeEventListener('keyup', (e) => {
      if (e.which === 27) {
        if (this.isOpen) this.close()
      }
    })

    this.eventBus.off('form-submitted', this.close)
    this.eventBus.off('popover-close', this.close)
  }

  init = () => {
    if (this.el.dataset.target) {
      this.el.addEventListener('click', this.open)
    }

    document.body.appendChild(this.popover)
    this.eventBus.on('popover-open', this.triggerOpen)
  }

  triggerOpen = (id) => {
    if (this.el.dataset.target === id) {
      this.open()
    }
  }

  open = (e) => {
    this.addEventListeners()
    this.eventBus.emit('opening-popover', e)

    if (e !== undefined) {
      e.preventDefault()
    }

    this.popover.classList.add('popover--open')
    document.body.classList.add('no-overflow')

    this.isOpen = true

    this.eventBus.emit('opened-popover')
  }

  close = () => {
    this.removeEventListeners()
    this.popover.classList.remove('popover--open')
    document.body.classList.remove('no-overflow')

    this.isOpen = false

    this.eventBus.emit('closed-popover', this.el.dataset.target)
  }
}
