import serialize from 'form-serialize'
import { data } from 'autoprefixer'

const defaultFetchOptions = {
  cache: 'no-cache',
  credentials: 'same-origin',
  mode: 'cors',
  redirect: 'follow',
  referrer: 'no-referrer'
}

export default class Suggest {
  static name = "suggest"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el
    this.input = this.el.querySelector('.search__input-text, .search-bar__input')
    this.reset = this.el.querySelector('.search__input-reset, .search-bar__reset')
    this.suggestList = this.el.querySelector('.suggest, .search-bar__suggest')
    this.vacancies = this.el.querySelector('.suggest__list--vacancy')
    this.isOpen = false
    this.openBackdrop = true
    this.closeOnScroll = this.el.dataset.suggestCloseOnScroll ? JSON.parse(this.el.dataset.suggestCloseOnScroll) : true
    this.resultKey = 'html'

    window.addEventListener('resize', this.handleOnResize.bind(this))
    this.handleOnResize()

    if(this.el.hasAttribute("data-desktop-backdrop")){
      this.openBackdrop = JSON.parse(this.el.dataset.desktopBackdrop)
    }

    this.input.addEventListener('keyup', (e) => {
      clearTimeout(this.debounce);

      if (this.input.value.length < 3 || e.keyCode === 27) {
        this.close()
      } else {
        this.debounce = window.setTimeout(() => {
          this.getSuggestResponse()
        }, 200)
      }
    })

    this.reset.addEventListener('click', (e) => {
      this.input.value = ''
      this.input.focus()
      this.close()
    })

    window.addEventListener('scroll', (e) => {
      if (this.isOpen && this.closeOnScroll) {
        this.close()
      }
    })
  }

  getSuggestResponse() {
    const formData = new FormData()
    const data = serialize(this.el, {hash: true})
    for (const key in data) {
      formData.append(key, data[key])
    }
    formData.append('sourceUrl', this.el.dataset.source)

    return fetch(this.el.dataset.suggestAction, Object.assign({}, defaultFetchOptions, {
      method: this.el.method,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData
    })).then(response => response.json()).then((response) => {
      this.handleResponse(response)
    }).catch(error => this.handleError(error))
  }

  handleError(error) {
    console.log(error)
  }

  handleResponse(response) {
    if (response.empty) {
      this.close();
      return true
    }
    if (!this.isOpen) {
      this.open()
    }
    this.suggestList.innerHTML = response[this.resultKey]
  }

  handleOnResize() {
    const datasetKey = window.matchMedia("(pointer: fine)").matches && window.innerWidth > 500 ? 'suggestResultDesktop' : 'suggestResultMobile'
    this.resultKey = this.el.dataset[datasetKey] || 'html'
  }

  open() {
    if (window.innerWidth < 768 || this.openBackdrop) {
      this.eventBus.emit('open-search-backdrop')
      this.el.style.zIndex = 5;
    }

    this.isOpen = true
    this.suggestList.classList.add('suggest--open')

    this.eventBus.on('nav-header-backdrop-clicked', this.close.bind(this))
  }

  close() {
    if (window.innerWidth < 768 || this.openBackdrop) {
      this.eventBus.emit('close-search-backdrop')
      this.el.style.zIndex = '';
    }

    this.isOpen = false
    this.suggestList.innerHTML = ''
    this.suggestList.classList.remove('suggest--open')


  }
}
