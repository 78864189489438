export default class Video {
  static name = "campaign-slider"

  constructor(el) {
    this.el = el
    this.videos = this.el.querySelectorAll('[data-video="true"]')
    this.timeout = null

    if (this.videos) {

      this.videos.forEach(video => {
        video.addEventListener('mouseenter', this.handleMouseEnter)
        video.addEventListener('mouseleave', this.handleMouseLeave)
      })
    }

    if (window.innerWidth < 500) {
      const observerOptions = {
        root: this.el,
        rootMargin: '0px',
        threshold: 0.2
      }
      this.observer = new IntersectionObserver((entries) => {
        this.handleIntersection(entries)
      }, observerOptions)

      this.videos.forEach(video => this.observer.observe(video))
    }
  }

  handleMouseEnter = (event) => {
    const video = event.currentTarget.querySelector('.image-card__video')
    this.playVideo(video)
  }

  handleMouseLeave = (event) => {
    const video = event.currentTarget.querySelector('.image-card__video')
    this.pauseVideo(video)
  }

  handleIntersection = (entries) => {
    entries.forEach(entry => {
      const video = entry.target.querySelector('.image-card__video')
      if (entry.isIntersecting) {
        video.style.opacity = 1
        this.playVideo(video)
      } else {
        video.style.opacity = 0
        this.pauseVideo(video)
      }
    })
  }


  playVideo = (video) => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    video.play()
  }

  pauseVideo = (video) => {
    this.timeout = setTimeout(() => {
      video.pause()
      video.currentTime = 0
    }, 300)

  }
}
