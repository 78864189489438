import VacancyMap from './vacancy-map'
import * as popover from './popover'
import initializeComponents from '../../core/libs/component-loader'

export default class Results {
  static name = 'results'

  static MAP_VIEW = 'map'
  static LIST_VIEW = 'list'

  constructor(el, controller, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.controller = controller

    this.items = this.el.querySelector('.results__items')
    this.resultCount = this.el.querySelector('.results__count')
    this.listButton = this.el.querySelector('.results__tool-button--list')
    this.mapButton = this.el.querySelector('.results__tool-button--map')

    this.view = Results.LIST_VIEW

    // this.init()
  }

  init = () => {
    this.listButton.addEventListener('click', this.switchToListView)
    this.mapButton.addEventListener('click', this.switchToMapView)
  }

  switchToListView = () => {
    if (this.view === Results.LIST_VIEW) {
      return
    }

    this.view = Results.LIST_VIEW
    this.el.classList.remove('results--map')
    this.el.classList.add('results--list')
    this.listButton.classList.remove('results__tool-button--inactive')
    this.mapButton.classList.add('results__tool-button--inactive')
  }

  switchToMapView = () => {
    if (this.view === Results.MAP_VIEW) {
      return
    }

    this.view = Results.MAP_VIEW
    this.el.classList.remove('results--list')
    this.el.classList.add('results--map')
    this.mapButton.classList.remove('results__tool-button--inactive')
    this.listButton.classList.add('results__tool-button--inactive')

    if (!this.map) {
      this.map = new VacancyMap(this.el.querySelector('.vacancy-map'), this.eventBus, this.mapItems)
    }
  }

  update = (items, banners, mapItems) => {
    this.resultCount.innerHTML = `${items.length} ${this.resultCount.dataset.suffix}`

    if (items.length === 0) {
      this.items.innerHTML = ''
    } else {
      // Insert banners at given index into result list
      if (banners.length > 0) {
        banners.forEach((banner) => {
          if (banner.idx < items.length) {
            items.splice(banner.idx, 0, banner.html)
          }
        })
      }
      this.items.innerHTML = items.join('\n')
      initializeComponents(this.items, [popover]);
    }

    // Directly update map or save items for when we switch
    if (this.map) {
      this.map.update(mapItems)
    } else {
      this.mapItems = mapItems
    }
  }
}
