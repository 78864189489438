export default class FormProgress {
  static name = 'form-progress'

  constructor(el, eventBus) {
    // This component will attach to both the static header, and the sticky header
    this.el = el
    this.eventBus = eventBus
    this.indicator = this.el.querySelector('.progress-bar__indicator')
    this.requiredStep = 0
    this.optionalStep = 0
    this.currentPosition = 0
    this.included_fields = ['first_name', 'last_name', 'email', 'phone']
    this.eventBus.on('update-progress-indicator', this.updateProgressIndicator.bind(this))
  }

  updateProgressIndicator(fields) {
    // Depending on where we are in the event flow, fields may have been added,
    // removed, or changed - so we need to process them from the start each time
    // we want to update the indicator
    this.currentPosition = 0
    this.requiredFields = []
    this.optionalFields = []

    // Determine required fields
    fields.forEach((fld) => {
      if (fld.required && this.included_fields.includes(fld.name)) {
        this.requiredFields.push(fld)
      }
    })

    // Note: optional fields not implemented yet

    // Determine indicator step size based on viewport width
    // Subtract some width to keep the indicator from overflowing
    this.viewportWidth = this.el.getBoundingClientRect().width - 50

    // Optional fields may not be present
    if (this.optionalFields.length > 0) {
      // Required steps are bigger than optional steps
      // Reserve one required step as space for (all) optional fields
      this.requiredStep = this.viewportWidth / (this.requiredFields.length + 1)
      this.optionalStep = this.requiredStep / (fields.length - this.requiredFields.length)
    } else {
      this.requiredStep = this.viewportWidth / this.requiredFields.length
    }

    this.determinePosition(fields)

    this.renderIndicator()
  }

  determinePosition(fields) {
    fields.forEach((fld) => {
      if (this.included_fields.includes(fld.name)) {
      this.incrementPosition(fld)
      }
    })
  }

  incrementPosition(fld) {
    // Determine position of indicator based on if field has been filled or not
    if ((fld.type === 'radio' || fld.type === 'checkbox') && fld.checked) {
      this.currentPosition += fld.required ? this.requiredStep : this.optionalStep
    }

    if (
      (fld.type === 'text' || fld.type === 'email' || fld.type === 'textarea' || fld.type === 'file') &&
      fld.value !== ''
    ) {
      this.currentPosition += fld.required ? this.requiredStep : this.optionalStep
    }
  }

  renderIndicator() {
    // When all fields have been processed and the indicator position has been determined,
    // render the indicator
    this.indicator.style.transform = `translateX(${this.currentPosition}px)`
  }
}
