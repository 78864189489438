export default class CodeBlock {
  static name = 'code-block'

  constructor(el, eventBus) {
    this.el = el
    this.highlights = this.el.querySelectorAll('.code-highlights__item')
    this.codeWindow = this.el.querySelector('.code-block__code')
    this.buttonNext = this.el.querySelector('.code-highlights__next')
    this.buttonPrev = this.el.querySelector('.code-highlights__prev')
    this.clickableBlocks = this.el.querySelectorAll('.code-block__highlighted')

    if (this.clickableBlocks.length > 0) {
      this.buttonPrev.addEventListener('click', () => {
        this.updateHighlight(false, true)
      })

      this.buttonNext.classList.remove('code-highlights__button--hidden')
      this.buttonNext.addEventListener('click', () => {
        this.updateHighlight(true, false)
      })

      for (const block of this.clickableBlocks) {
        block.addEventListener(
          'click', () => {
            this.showHighlight(block.dataset.id)
          }
        )
      }
    }
  }

  showHighlight = (id, scroll) => {
    this.el.classList.add('code-block--highlight-active')

    for (const el of this.highlights) {
      el.classList.remove('code-highlights__item--active')
      if (el.dataset.id === id) {
        el.classList.add('code-highlights__item--active')
      }
    }

    for (const el of this.clickableBlocks) {
      el.classList.remove('code-block__highlighted--active')
      if (el.dataset.id === id) {
        el.classList.add('code-block__highlighted--active')
        if (scroll) {
          this.scrollToCode(el)
        }
      }
    }
    this.updateButtons()
  }

  scrollToCode = (el) => {
    let top
    const rect = this.el.getBoundingClientRect()

    if (window.innerWidth > 767) {
      top = (window.scrollY + rect.top) - (window.innerHeight / 2) + (rect.height / 2)
    } else {
      top = (window.scrollY + rect.top) - 60 // = navbar height + little margin
    }

    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    })

    this.codeWindow.scrollTop = el.offsetTop - 200
  }

  nextHighlightId = () => {
    let next = 0
    const numberOfHighlights = this.highlights.length

    for (let i = 0; i < numberOfHighlights; i++) {
      if (this.highlights[i].classList.contains('code-highlights__item--active')) {
        next = i + 1
      }
    }

    if (next >= numberOfHighlights) {
      return
    }

    return this.highlights[next].dataset.id
  }

  previousHighlightId = () => {
    let prev = 0
    const numberOfHighlights = this.highlights.length

    for (let i = 0; i < numberOfHighlights; i++) {
      if (this.highlights[i].classList.contains('code-highlights__item--active')) {
        prev = i - 1
      }
    }

    if (prev < 0) {
      return
    }

    return this.highlights[prev].dataset.id
  }

  updateHighlight = (next, previous) => {
    const nextId = this.nextHighlightId()
    const prevId = this.previousHighlightId()

    if (next && nextId) {
      this.showHighlight(nextId, true)
    } else if (previous && prevId) {
      this.showHighlight(prevId, true)
    }
  }

  updateButtons = () => {
    const hiddenClass = 'code-highlights__button--hidden'
    const nextId = this.nextHighlightId()
    const prevId = this.previousHighlightId()
    nextId ? this.buttonNext.classList.remove(hiddenClass) : this.buttonNext.classList.add(hiddenClass)
    prevId ? this.buttonPrev.classList.remove(hiddenClass) : this.buttonPrev.classList.add(hiddenClass)
  }

}
