import ConfettiGenerator from 'confetti-js'

export default class Confetti {
  static name = "confetti"

  settings = {
    'max': '60',
    'size': '1',
    'animate': true,
    'props': ['square'],
    'colors': [[255,102,0], [40,93,171],  [255, 255, 255]],
    'clock': '25',
    'rotate': true,
    'start_from_edge': true,
    'respawn': true
  }

  constructor(el, eventBus) {
    let settings = this.settings
    settings.target = el
    this.init(settings)
  }

  init(settings) {
    const confetti = new ConfettiGenerator(settings)
    confetti.render()
  }
}
