import GADataLayer from '../../core/libs/ga-data-layer'

export default class SubForm {
  static name = 'subform'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.fields = [...this.el.querySelectorAll('input, select')]
    this.locationField = this.el.querySelector('[name="location"]')
    this.contractOpts = [...this.el.querySelectorAll('[name="contract"]')]
    this.hourOpts = [...this.el.querySelectorAll('[name="hours"]')]
    this.submitButton = this.el.querySelector('.subform__form button')
    this.toggleConfirmationButton = this.el.querySelector('[data-do="show-confirmation"]')
    this.hoursRequired = false

    if (typeof popupFormOptions !== 'undefined') {
      this.formOptions = popupFormOptions
    }

    this.el.addEventListener('change', (e) => {
      this.update()
    })

    this.el.addEventListener('submit', (e) => {
      const ga = new GADataLayer()

      let data = {
        openHarver: this.el.dataset.harverFlow,
        selectedLocation: this.locationField.value,
        selectedContract: this.el.elements.namedItem('contract').value,
      }

      if (this.el.elements.namedItem('hours')) {
        data.selectedHours = this.el.elements.namedItem('hours').value
      }

      ga.push('popUpFormSubmitted', data)
      this.eventBus.emit('form-submitted')
    })

    if (this.toggleConfirmationButton) {
      this.toggleConfirmationButton.addEventListener('click', this.toggleConfirmation)
    }

    this.eventBus.on('closed-popover', this.resetConfirmation)

    this.update()
  }

  getSelectedContract () {
    const checkedEl = this.el.querySelector('input[name="contract"]:checked, input[name="contract_option"]:checked')
    if (!checkedEl) {
      return ''
    } else {
      return checkedEl.value
    }
  }

  getSelectedHours () {
    if (this.hourOpts.length === 0) {
      return ''
    }
    const checkedEl = this.el.querySelector('input[name="hours"]:checked, input[name="hours_option"]:checked')
    if (!checkedEl) {
      return ''
    } else {
      return checkedEl.value
    }
  }

  isValid () {
    let validHours = true
    if (this.hoursRequired) {
      validHours = this.getSelectedHours() !== ''
    }
    return this.getSelectedContract() !== '' && this.el.location !== '' && validHours
  }

  update () {
    this.updateContract()
    this.updateHours()

    if (this.submitButton && this.isValid()) {
      this.submitButton.removeAttribute('disabled')
    } else if (this.submitButton && !this.isValid()) {
      this.submitButton.disabled = true
    }
    this.eventBus.emit('subform-updated')
  }

  updateContract () {
    // Create a list of all valid contracts for this location
    const validContracts = []

    if (this.formOptions) {
      const fieldFormOptions = this.formOptions[this.locationField.value]
      if (fieldFormOptions) {
        for (let validOption of Array.from(fieldFormOptions.contracts)) {
          validContracts.push(validOption.name)
        }
      }
    }

    this.contractOpts.forEach((opt) => {
      opt.disabled = validContracts.indexOf(opt.getAttribute('value')) < 0
      if (opt.disabled) {
        opt.checked = false
      }
    })

    if (validContracts.length === 1) {
      this.el.querySelector(`[value="${validContracts[0]}"]`).checked = true
    }
  }

  updateHours () {
    if (this.hourOpts.length === 0) {
      return
    }

    const contract = this.getSelectedContract()
    let hoursRow = this.el.querySelector('.form__row--hours, .form__row--hours_option')

    if (!hoursRow) {
      return
    }

    if (!contract) {
      hoursRow.classList.add('form__row--hidden')
      return
    }

    let contractOptions = []

    if (this.formOptions) {
      contractOptions = Array.from(this.formOptions[this.locationField.value].contracts).filter(
        (x) => x.name === contract
      )
    }

    let validHours = []

    if (contractOptions && contractOptions.length) {
      validHours = contractOptions[0].hours
    }

    // Determine if this contract has hour fields
    if (validHours) {
      this.hoursRequired = true
    } else {
      this.hoursRequired = false
    }

    // Loop through all hour options and show/hide them
    let atLeastOneHourOptVisible = false
    this.hourOpts.forEach((opt) => {
      opt.disabled = validHours.indexOf(opt.getAttribute('value')) < 0
      let formOption = opt.closest('.form__option')
      if (formOption && opt.disabled) {
        formOption.classList.add('form__option--hidden')
        opt.checked = false
      } else if (formOption && !opt.disabled) {
        formOption.classList.remove('form__option--hidden')
        atLeastOneHourOptVisible = true
      }
    })

    if (hoursRow && atLeastOneHourOptVisible) {
      this.el.querySelector('.form__row--hours, .form__row--hours_option').classList.remove('form__row--hidden')
    } else if (hoursRow && !atLeastOneHourOptVisible) {
      this.el.querySelector('.form__row--hours, .form__row--hours_option').classList.add('form__row--hidden')
    }

    let hoursChecked = false
    this.el.querySelectorAll('.form__option:not(.form__option--hidden) [name=hours_option]').forEach((opt) => {
      if (opt.checked) {
        hoursChecked = true
      }
    })
    console.log("UpdateHours", this.hourOpts, hoursChecked)
    if (!hoursChecked) {
      // If no hours have been checked yet, make sure the first one is checked
      let inputEls = this.el.querySelectorAll('.form__option:not(.form__option--hidden) [name=hours_option]')
      if (inputEls && inputEls.length > 0) {
        inputEls[0].checked = true
      }
    }

    this.toggleNotification(hoursChecked)
  }

  toggleNotification (show) {
    const notification = this.el.querySelector('.form__notification')
    if (!notification) {
      return
    }
    if (show) {
      notification.classList.add('form__notification--visible')
    } else {
      notification.classList.remove('form__notification--visible')
    }
  }

  toggleConfirmation = () => {
    const form = this.el.querySelector('.subform__form')
    const confirmation = this.el.querySelector('.subform__confirmation')

    form.classList.add('subform__form--hidden')
    confirmation.classList.remove('subform__confirmation--hidden')
  }

  resetConfirmation = () => {
    const form = this.el.querySelector('.subform__form')
    const confirmation = this.el.querySelector('.subform__confirmation')

    if (form) {
      form.classList.remove('subform__form--hidden')
    }
    if (confirmation) {
      confirmation.classList.add('subform__confirmation--hidden')
    }
  }
}
