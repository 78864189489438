export default class NavList {

  static name = "nav-list"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el
    this.buttonEl = this.el.querySelector('.nav-list__button')
    if (this.buttonEl) {
      this.buttonEl.addEventListener('click', this.close)
    }

    const siteSwitchButton = this.el.querySelector('.nav-list__language-switch-button')
    if (siteSwitchButton) {
      siteSwitchButton.addEventListener('click', this.openSiteSwitch)
    }

    this.eventBus.on('open-nav-list', this.open)
  }

  open = () => {
    this.el.classList.add('nav-list--open')

    this.eventBus.off('open-nav-list', this.open)
    this.eventBus.on('close-nav-list', this.close)
  }

  close = () => {
    this.el.classList.remove('nav-list--open')
    this.eventBus.emit('closed-nav-list')

    this.eventBus.on('open-nav-list', this.open)
    this.eventBus.off('close-nav-list', this.close)

    setTimeout(this.onClosed, 300)
  }

  onClosed = () => {
    this.el.classList.remove('nav-list--show-languages')
  }

  openSiteSwitch = () => {
    this.el.classList.add('nav-list--show-languages')
  }
}
