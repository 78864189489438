import GADataLayer from '../../core/libs/ga-data-layer'

export default class RecurringEventPopover {
  static name = 'recurring-event-popover'

  constructor(el, eventBus) {
    this.el = el
    this.ga = new GADataLayer()
    this.baseUrl = this.el.dataset.baseUrl
    this.slug = this.el.dataset.eventSlug
    this.eventBus = eventBus
    this.eventCards = [...this.el.querySelectorAll('.recurring-event-card-list__item')]
    this.eventResults = [...this.el.querySelectorAll('input[type="radio"]')]
    this.submitButton = this.el.querySelector('button[type="submit"]')

    this.setEventHandlers()
  }

  setEventHandlers() {
    this.eventBus.on('opening-popover', (e) => {
      // Reset form whenever the popover is opened
      this.el.reset()
      this.submitButton.disabled = true

      // Only preselect a radio button when the popover is
      // opened via the submit button
      if (e.target.classList.contains('event-header__button')) {
        // Preselect current slug
        this.eventResults.forEach((result) => {
          if (result.value === this.slug) {
            result.checked = true
            this.submitButton.disabled = false
          }
        })
      }
    })

    // Clickable cards
    this.eventCards.forEach((card) => {
      card.addEventListener('click', (e) => {
        // Uncheck other radio buttons
        this.eventResults.forEach((result) => {
          result.checked = false
        })
        // Check this radio button
        const input = card.querySelector('input[type="radio"]')
        input.checked = true

        this.slug = input.value // Set target url
        this.submitButton.disabled = false // Enable submit button
      })
    })

    // Submit form on submit button click
    this.el.addEventListener('submit', (e) => {
      this.onSubmit(e)
    })
  }

  onSubmit(e) {
    e.preventDefault()
    this.ga.push('apply_for_job', { location: e.target.dataset.eventName })

    const url = `${this.baseUrl}${this.slug}/signup/`
    window.location = url
  }
}
