export default class PageTitleExperiment {
  static name = 'page-title-experiment'

  interval

  constructor(el, eventbus) {
    this.el = el
    this.originalTitle = document.title
    this.alternativeTitle = this.el.dataset.alternativeTitle

    window.onblur = this.onBlur
    window.onfocus = this.onFocus
  }

  active = () => {
    return true
    // return document.body.classList.contains('page-title-experiment')
  }

  startBlink = () => {
    this.interval = setInterval(this.toggleTitle, 3000);
  }

  stopBlink = () => {
    clearInterval(this.interval);
    document.title = this.originalTitle
  }

  toggleTitle = () => {
    if (document.title === this.alternativeTitle) {
      document.title = this.originalTitle
    } else {
      document.title = this.alternativeTitle
    }
  }

  onBlur = () => {
    if (this.active()) {
      this.startBlink()
    }
  }

  onFocus = () => {
    this.stopBlink()
  }
}
