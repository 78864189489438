const defaultFetchOptions = {
  cache: 'no-cache',
  credentials: 'same-origin',
  mode: 'same-origin'
}

export default class Planner {
  static name = 'planner'

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.contentEl = this.el.querySelector('.planner__content')
    this.csrfToken = this.el.querySelector('input[name="csrfmiddlewaretoken"]').value

    eventBus.once('opened-popover', this.loadTimeSchedule)
  }

  loadTimeSchedule = () => {
    this.unloadSchedule()

    fetch(this.el.dataset.timeScheduleUrl, Object.assign({}, defaultFetchOptions, {
      method: 'GET',
    }))
      .then(response => response.text())
      .then((response) => {
        this.onTimeScheduleLoaded(response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  onTimeScheduleLoaded = (content) => {
    this.contentEl.innerHTML = content

    this.dateSelector = this.contentEl.querySelector('#planner-date')
    this.dateSelector.addEventListener('change', this.onDateChanged)

    this.timeRowEl = this.contentEl.querySelector('.planner__row--time')
    this.timeRowEl.addEventListener('change', this.onTimeChanged)

    this.submitRowEl = this.contentEl.querySelector('.planner__row--submit')

    this.formEl = this.contentEl.querySelector('.planner__form')
    this.formEl.addEventListener('submit', this.onSubmit)
  }

  unloadSchedule = () => {
    if (this.dateSelector) {
      this.dateSelector.removeEventListener('change', this.onDateChanged)
    }
    if (this.timeRowEl) {
      this.timeRowEl.removeEventListener('change', this.onTimeChanged)
    }
    if (this.formEl) {
      this.formEl.removeEventListener('submit', this.onSubmit)
    }
  }

  onDateChanged = () => {
    const option = this.dateSelector.options[this.dateSelector.selectedIndex]
    const index = option.dataset.index

    // Hide submit row with button
    this.submitRowEl.classList.remove('planner__row--visible')

    // Show/hide the row which contains the slots
    if (this.dateSelector.selectedIndex > 0 && this.dateSelector.selectedIndex < this.dateSelector.options.length - 1) {
      this.timeRowEl.classList.add('planner__row--visible')
    } else {
      this.timeRowEl.classList.remove('planner__row--visible')
    }

    if(this.dateSelector.selectedIndex === this.dateSelector.options.length - 1) {
      this.submitRowEl.classList.add('planner__row--visible')
    }

    // Toggle the correct slots for this day.
    for (const slot of this.el.querySelectorAll('.planner__slots')) {
      if (slot.dataset.index === index) {
        slot.classList.add('planner__slots--visible')
      } else {
        slot.classList.remove('planner__slots--visible')
      }
    }

    // Reset all radio buttons
    for (const time of this.el.querySelectorAll('input[name="planner-time"]')) {
      time.checked = ''
    }
  }

  onTimeChanged = () => {
    this.submitRowEl.classList.add('planner__row--visible')
  }

  onBookingError = (content) => {
    if (!content) {
      // Content can be empty (to make the fetch promise easier), in that case do nothing
      return
    }
    this.contentEl.innerHTML = content

    const reloadLink = this.el.querySelector('[data-reload="true"]')
    if (reloadLink) {
      reloadLink.addEventListener('click', (e) => {
        e.preventDefault()
        this.loadTimeSchedule()
      }, { once: true })
    }
  }

  getValues = () => {
    let values = {
      'friendlyDate': '',
      'date': '',
      'time': ''
    }
    values.friendlyDate = this.dateSelector.options[this.dateSelector.selectedIndex]
    values.date = this.dateSelector.value
    const timeEl = this.el.querySelector('input[name="planner-time"]:checked')
    if (timeEl) {
      values.time = timeEl.value
    }
    return values
  }

  onSubmit = (e) => {
    e.preventDefault()

    const values = this.getValues()
    if (!values.date || (values.date !== 'not-available' && !values.time)) {
      return
    }

    const formData = new FormData()
    formData.append('date', values.date)
    formData.append('time', values.time)

    fetch(this.el.dataset.bookUrl, Object.assign({}, defaultFetchOptions, {
      method: 'POST',
      headers: { 'X-CSRFToken': this.csrfToken },
      body: formData
    }))
      .then(response => {
        if (!response.ok) {
          return response.text()
        } else {
          this.eventBus.emit("planner-booked", values)
          return false
        }
      })
      .then(error => this.onBookingError(error))
      .catch(error => {
        console.log(error)
      })
  }
}
