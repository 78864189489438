export default class FilterFormRow {
  static name = 'filter-form-row'

  constructor(el, controller, eventBus) {
    this.el = el
    this.eventBus = eventBus

    this.showAllButton = this.el.querySelector(".filter-form__show-all")
    if (this.showAllButton) {
      this.showAllButton.addEventListener("click", this.showAll)
    }
  }

  showAll = () => {
    const collapsedOptions = [...this.el.querySelectorAll(".filter-form__checkbox--collapsed")]
    Array.from(collapsedOptions).forEach((option) => {
      option.classList.remove("filter-form__checkbox--collapsed")
    })
    this.showAllButton.style.display = "none"
  }
}
