const defaultFetchOptions = {
  cache: 'no-cache',
  credentials: 'same-origin',
  mode: 'same-origin'
}

export default class UpdateCandidateForm {
  static name = 'update-candidate-form'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.success = false
    this.generalError = false
    this.validationError = false
    this.successMessage = this.el.querySelector('.toast--success')
    this.errorMessage = this.el.querySelector('.toast--error')
    this.validationErrorContainer = this.el.querySelector('.form__validation-errors')

    this.el.addEventListener('submit', this.handleSubmit)
    this.eventBus.on('closed-popover', this.resetForm)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)

    fetch(this.el.dataset.updateUrl, Object.assign({}, defaultFetchOptions, {
      method: 'POST',
      headers: { 'X-CSRFToken': formData['csrfToken'] },
      body: formData
    }))
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.errors) {
          throw new ValidationError('formdata is not valid', data.errors)
        }

        this.success = true
        this.generalError = false
        this.validationError = false

        sessionStorage.setItem("candidate", JSON.stringify(data));
        this.eventBus.emit('update-candidate-details', data)
      })
      .catch(error => {
        if (error.name === 'ValidationError') {
          this.validationError = true
          this.generalError = false
          this.success = false

          Object.values(error.errors).forEach(error => {
            error.forEach(message => {
              const errorMessage = this.createValidationErrorMessage(message)
              this.validationErrorContainer.appendChild(errorMessage)
            })
          })
          return
        }

        this.generalError = true
        this.success = false
        this.validationError = false
        return
      })
      .finally(() => {
        this.successMessage.style.display = this.success ? 'block' : 'none'
        this.errorMessage.style.display = this.generalError ? 'block' : 'none'
        this.validationErrorContainer.style.display = this.validationError ? 'block' : 'none'
      })
  }

  resetForm = (target) => {
    if (target !== 'popover-update-candidate') {
      return
    }

    this.success = false
    this.error = false
    this.validationError = false

    this.successMessage.style.display = 'none'
    this.errorMessage.style.display = 'none'
    this.validationErrorContainer.style.display = 'none'
    this.validationErrorContainer.innerHTML = ''
  }

  createValidationErrorMessage = (message) => {
    const element = document.createElement('p', {})
    element.classList.add('toast', 'toast--error')
    element.innerHTML = message
    return element
  }
}

class ValidationError extends Error {
  constructor (message, errors) {
    super(message)
    this.name = 'ValidationError'
    this.errors = errors
  }
}
