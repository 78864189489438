export default class ResultsFloatingBar {
  static name = 'results-floating-bar'

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    this.eventBus.on('floating-bar', this.update)
  }

  update = (options) => {
    if (options.float) {
      this.el.classList.add('results__floating-bar--floating')
    } else {
      this.el.classList.remove('results__floating-bar--floating')
    }
  }
}
