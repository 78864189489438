import Swiper, { Navigation, Thumbs, Pagination } from 'swiper'

export default class Gallery {
  static name = 'gallery'

  constructor (el) {
    this.el = el
    this.thumbsEl = this.el.querySelector('.gallery-block__swiper-thumbs')
    this.mainEl = this.el.querySelector('.gallery-block__swiper-main')

    this.setThumbsHeight()
    this.initSliders()
  }

  setThumbsHeight = () => {
    this.el.style.display = 'block'
    this.el.style.width = '100%'
    this.el.style.position = 'absolute'
    this.el.style.visibility = 'hidden'

    const height = this.mainEl.getBoundingClientRect().height
    this.thumbsEl.style.height = `${height}px`

    this.el.style.display = ''
    this.el.style.width = ''
    this.el.style.position = ''
    this.el.style.visibility = ''
  }

  initSliders = () => {
    const thumbsCount = this.thumbsEl.querySelectorAll('.gallery-block__slide').length

    this.swiperThumbs = new Swiper('.gallery-block__swiper-thumbs', {
      direction: 'vertical',
      loop: false,
      slidesPerView: thumbsCount === 3 ? 3 : 'auto',
      spaceBetween: 8,
    })

    this.swiperMain = new Swiper('.gallery-block__swiper-main', {
      direction: 'horizontal',
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 12,
      modules: [Navigation, Pagination, Thumbs],
      on: {
        slideChange: () => {
          this.swiperThumbs.slideTo(this.swiperMain.activeIndex)
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
      thumbs: {
        swiper: this.swiperThumbs,
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
          centeredSlides: true
        }
      }
    })
  }
}
