export default class Foldout {
  static name = "foldout"

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.panels = [...this.el.querySelectorAll('.panel')]

    if(this.el.classList.contains('procedure__steps') && window.matchMedia("(pointer: fine)").matches && window.innerWidth > 500) {
      return
    }

    this.setEventHandlers()
  }

  setEventHandlers () {
    this.panels.forEach((panel) => {
      panel.querySelector('.panel__title').addEventListener('click', () => {
        panel.classList.toggle('panel--open')
      })
    })
  }
}
