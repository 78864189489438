export default class Slider {
  static name = "slider"

  constructor (el, eventBus) {
    this.eventBus = eventBus
    this.el = el
    this.scrollingContainer = this.el.querySelector('[data-role="scrolling-container"]')
    this.scrollingElements = this.el.querySelectorAll('[data-role="scrolling-element"]')

    if(this.el.classList.contains('procedure') && window.matchMedia("(pointer: coarse)").matches && window.innerWidth <= 500) {
      return
    }

    // scroll the container left and right
    Array.from(this.el.querySelectorAll('[data-role="scroll-button"]')).forEach((button) => {
      button.addEventListener('click', () => {
        button.blur()
        if (button.dataset.direction === 'previous') {
          this.scrollingContainer.scrollLeft -= this.scrollingElements[0].getBoundingClientRect().width
        } else {
          this.scrollingContainer.scrollLeft += this.scrollingElements[0].getBoundingClientRect().width
        }
      })
    })

    // when the user scrolls we want to add a hidden class to the pages not in view.
    if ('IntersectionObserver' in window) {
      const intersectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          entry.target.classList.toggle('slider__item--hidden', !entry.isIntersecting)

          // we want to hide the buttons on the beginning and ending of the scrolling
          if (entry.target === this.scrollingElements.item(0)) {
            this.el.classList.toggle('slider--start', entry.isIntersecting)
          }

          if (entry.target === this.scrollingElements.item(this.scrollingElements.length - 1)) {
            this.el.classList.toggle('slider--end', entry.isIntersecting)
          }
        })
      }, { root: this.scrollingContainer, threshold: 0.75 })

      Array.from(this.scrollingElements).forEach((element) => {
        intersectionObserver.observe(element)
      })
    } else {
      this.el.classList.remove('slider__container--start')
    }
  }
}
