export default class Bus {
  static name = "bus"

  driving = false

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.eventBus.on('drive', this.drive)
  }

  drive = () => {
    if (!this.driving) {
      this.el.classList.add("bus--drive")
      this.driving = true

      setTimeout(() => {
        this.driving = false
        this.el.classList.remove("bus--drive")
      }, 5000)
    }
  }
}
