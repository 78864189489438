import GADataLayer from '../../core/libs/ga-data-layer'

export default class ApplyButton {
  static name = 'apply-button'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.ga = new GADataLayer()

    this.eventBus.on('trigger-apply-button', (eventName) => {
      this.eventName = eventName
      this.el.click()
    })

    this.el.addEventListener('click', this.triggerGAEvent)
  }

  triggerGAEvent = (event) => {
    const value = event.isTrusted ? event.target.dataset.eventName : this.eventName
    this.ga.push('apply_for_job', { location: value })
  }
}
