import { bareCookies } from '../../core/libs/utils'

export default class CookieSettings {
  static name = 'cookie-settings'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    this.el.addEventListener('change', (event) => {
      this.setCookie(event.target.value)
      document.location.reload()
    })

    this.init()
  }

  init () {
    const cookieValue = bareCookies.get(this.el.dataset.cookieName)

    if (cookieValue != undefined) {
      this.el.querySelector(`input[value="${cookieValue}"]`).checked = true
    }
  }

  setCookie (val) {
    const options = {
      expires: 365,
      secure: location.protocol === 'https:',
      sameSite: 'lax'
    }

    bareCookies.set(this.el.dataset.cookieName, val, options)
  }
}
