export default class ApplyBar {
  static name = "apply-bar"

  constructor (el) {
    this.el = el
    this.element = document.querySelector(this.el.dataset.element)
    this.button = this.el.querySelector('.apply-bar__button')
    this.init()
  }

  init () {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio === 0 && entry.boundingClientRect.y < 0) {
          this.el.classList.add('vacancy-page__apply-bar--visible')
        } else {
          this.el.classList.remove('vacancy-page__apply-bar--visible')
        }
      })
    }, { threshold: 0 })
    observer.observe(this.element)

    this.button.addEventListener('click', (e) => {
      const applyButton = document.querySelector('.vacancy-header__button')
      const headerForm = document.querySelector('.header-form')
      if (applyButton) {
        if (headerForm) {
          headerForm.scrollIntoView({ behavior: 'smooth'})
        } else if (applyButton.dataset.initialized === 'true') {
          const popover = applyButton.getComponent('popover')
          if (popover) {
            popover.open()
          } else {
            applyButton.click()
          }
        } else {
          applyButton.click()
        }
      }
    })
  }
}
