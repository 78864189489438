import { EventEmitter } from 'events'
import CalendarPopOver from '../../website/controllers/calendar-popover.js'

const eventBus = new EventEmitter()
eventBus.setMaxListeners(32)

export default function initialize (container, components) {
  Array.from(container.querySelectorAll(
    '[data-component]:not([data-initialized])'),
  )
    .map((el) => {
      el.dataset.initialized = true
      el.__components__ = {};
      [...el.dataset.component.trim()
        .replace(/\s+/, ' ')
        .trim()
        .split(' ')].filter(c => c)
        .forEach((componentName) => {
          const filtered_components = components.filter(c => c.default.name === componentName)
          if (filtered_components.length === 1) {
            const Component = filtered_components[0].default
            try {
              el.__components__[componentName] = new Component(el, eventBus)
              el.getComponent = name => el.__components__[name]
            } catch (error) {
              console.error(error)
            }
          } else {
            console.log(`Could not find Component for ${componentName}`)
          }
        })
      return el.__components__
    })

  window.calendarPopover = new CalendarPopOver(eventBus)
}
