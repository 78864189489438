import PopOver from './popover'

export default class YoutubePopOver extends PopOver {
  static name = "youtube-popover"

  constructor(el, eventBus) {
    super(el, eventBus)
    this.eventBus = eventBus
    this.el = el
    this.popover = this.el.querySelector('.popover-youtube')

    this.youtubeLinks = this.el.querySelectorAll("a[href*='youtube.com']")
    this.youtubeLinks.forEach(link => {
      link.addEventListener('click', this.handleClick)
    })

    this.buttonEl = this.popover.querySelector('.popover__button')
    this.backdropEl = this.popover.querySelector('.popover__backdrop')
    this.init()
  }

  handleClick = (event) => {
    event.preventDefault()
    const youtubeUrl = new URL(event.currentTarget.href)
    const urlSearchParams = new URLSearchParams(youtubeUrl.search);
    const youtubeId = urlSearchParams.get('v');

    const videoElement = this.popover.querySelector('.video')
    videoElement.style.backgroundImage = `url('https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg')`
    videoElement.dataset.videoId = youtubeId

    const ytVideo = window.ytVideos.find(v => v.elm.dataset.videoId === youtubeId)
    ytVideo.player.cueVideoById(youtubeId)

    this.open()
  }
}
