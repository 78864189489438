export default class LanguageSwitchButton {
  static name = "language-switch-button"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el

    this.eventBus.on('opened-language-switch', this.open)
    this.eventBus.on('closed-language-switch', this.close)
  }

  open = () => {
    this.el.classList.add('language-switch-button--active')
  }

  close = () => {
    this.el.classList.remove('language-switch-button--active')
  }
}
