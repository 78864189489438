import EventResults from './event-results'
import Cookies from 'js-cookie'

const defaultFetchOptions = {
  cache: 'no-cache',
  credentials: 'same-origin',
  mode: 'cors',
  redirect: 'follow',
  referrer: 'no-referrer'
}

export default class Events {
  static name = 'events'

  loadingClass = 'vacancies--loading'
  emptyClass = 'vacancies--empty'
  errorClass = 'vacancies--error'
  running = null

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.action = '/event-filter/'
    this.method = 'POST'
    this.params = this.setParams()

    this.results = new EventResults(this.el.querySelector(".event-result-grid"), this, eventBus)

    this.pastEventsButton = this.el.querySelector('.button--past-events')
    this.currentEventsButton = this.el.querySelector('.button--current-events')
    this.currentEventsCounter = this.el.querySelector('.event-results__count')

    // Event listeners
    this.pastEventsButton.addEventListener('click', () => {
      // Handle buttons status
      this.currentEventsCounter.classList.add('event-results__count--hidden')
      this.pastEventsButton.classList.add('event__filter-button--active')
      this.currentEventsButton.classList.remove('event__filter-button--active')

      // Fetch past events
      this.params = this.setParams('past-events')
      this.updateResults(this.params)
    })
    this.currentEventsButton.addEventListener('click', () => {
      // Handle buttons status
      this.currentEventsCounter.classList.remove('event-results__count--hidden')
      this.pastEventsButton.classList.remove('event__filter-button--active')
      this.currentEventsButton.classList.add('event__filter-button--active')

      // Fetch current events
      this.params = this.setParams('current-events')
      this.updateResults(this.params)
    })

    // Fetch current events on initial page load
      this.updateResults(this.params)
  }

  setParams(scope = 'current-events') {
    const params = new FormData()

    params.append('sourceUrl', this.el.dataset.source)
    params.append('initialCount', this.el.dataset.initialCount)
    params.append('resultScope', scope)

    return params
  }

  updateResults(params) {
    this.resetResults()
    this.el.classList.add(this.loadingClass)
    if (this.running) {
      this.running.abort()
    }
    this.running = this.fetchData(params)
  }

  fetchData(params, action = '/event-filter/', method = 'POST') {
    const controller = new AbortController();
    const { signal } = controller;
    const opts = Object.assign({ signal }, defaultFetchOptions, {
      method: method,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: params
    })
    return {
      abort: () => controller.abort(),
      ready: fetch(action, opts)
        .then(response => response.json())
        .then((response) => {
          this.handleResponse(response)
        })
        .catch(error => this.handleError(error))
    }
  }

  handleResponse(response) {
    this.el.classList.remove(this.loadingClass)

    const events = response.events
    const current_events_counter = response.current_events_counter

    this.results.update(events, current_events_counter)

    this.eventBus.emit('resetLoadMore')
  }

  handleError(error) {
    if (error.name !== 'AbortError') {
      this.el.classList.remove(this.loadingClass)
      this.el.classList.add(this.errorClass)
    }
  }

  resetResults() {
    if (this.el.classList.contains(this.errorClass)) {
      this.el.classList.remove(this.errorClass)
    }
    if (this.el.classList.contains(this.emptyClass)) {
      this.el.classList.remove(this.emptyClass)
    }
  }
}
