
export default class EventResults {
  static name = 'event-results'

  constructor(el, controller, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.controller = controller

    this.items = this.el.querySelector('.event-results')
    this.resultCount = this.el.querySelector('.event-results__count')
  }

  update = (events, current_events_counter) => {
    this.resultCount.innerHTML = `${current_events_counter} ${this.resultCount.dataset.suffix}`

    if (events.length === 0) {
      this.items.innerHTML = ''
    } else {
      this.items.innerHTML = events.join('\n')
    }
  }
}
