export default class NavHeader {
  static name = 'nav-header'

  lastScrollTop
  floating = false
  sticky = false

  constructor (el, eventBus) {
    this.eventBus = eventBus
    this.el = el
    this.backdrop = this.el.querySelector('.nav-header__search-backdrop')
    this.backDropIsOpen = false
    this.searchIsOpen = false

    const openMenuButtons = el.querySelectorAll('[data-do="open-menu"]')
    for (const button of openMenuButtons) {
      button.addEventListener('click', this.openNavList)
    }

    const siteSwitchButton = this.el.querySelector('.nav-header__language-switch-button')
    if (siteSwitchButton) {
      siteSwitchButton.addEventListener('click', this.toggleLanguageSwitch)
    }

    const accountMenuButton = this.el.querySelector('.nav-header__account-menu-button')
    if (accountMenuButton) {
      accountMenuButton.addEventListener('click', this.toggleAccountMenu)
    }

    const largeLogo = this.el.querySelector('.nav-header__large-logo')
    if (largeLogo) {
      largeLogo.addEventListener('click', this.driveBus)
    }

    [...this.el.querySelectorAll('.nav-header__link')].forEach((link) => {
      if (document.location.pathname.length > 1 && link.getAttribute('href').indexOf(document.location.pathname) > 0) {
        link.classList.add('nav-header__link--active')
      }
    })

    this.backdrop.addEventListener('click', () => {
      this.eventBus.emit('nav-header-backdrop-clicked')
    })

    this.eventBus.on('opened-search', this.openSearch)
    this.eventBus.on('open-search-backdrop', this.openBackdrop.bind(this))

    window.setInterval(this.check, 1000 / 60)
  }

  openNavList = () => {
    this.eventBus.emit('open-nav-list')
  }

  toggleLanguageSwitch = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.eventBus.emit('toggle-language-switch')
  }

  toggleAccountMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.eventBus.emit('toggle-account-menu')
  }

  driveBus = () => {
    this.eventBus.emit('drive')
  }

  openSearch = () => {
    if (!this.searchIsOpen) {
      this.el.classList.add('nav-header--search')

      this.eventBus.off('opened-search', this.openSearch)
      this.eventBus.on('closed-search', this.closeSearch)

      this.searchIsOpen = true
    }
  }

  closeSearch = () => {
    if (this.searchIsOpen) {
      this.el.classList.remove('nav-header--search')

      this.eventBus.on('opened-search', this.openSearch)
      this.eventBus.off('closed-search', this.closeSearch)
      this.backDropIsOpen = false

      this.closeBackdrop()

      this.searchIsOpen = false
    }
  }

  getScrollTop = () => {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
  }

  update = () => {
    if (this.floating) {
      this.el.classList.add('nav-header--float')
      this.el.classList.remove('nav-header--large-logo')
    } else {
      this.el.classList.remove('nav-header--float')
      this.el.classList.remove('nav-header--anim')
    }

    if (this.sticky) {
      this.el.classList.add('nav-header--sticky')
      this.el.classList.add('nav-header--anim')
    } else {
      this.el.classList.remove('nav-header--sticky')
    }

    this.eventBus.emit('close-language-switch')
  }

  determineThreshold = () => {
    if (this.el.classList.contains('nav-header--large-logo') &&
      window.innerWidth > 767) {
      return 175
    }
    return 70
  }

  check = () => {
    let scrollTop = this.getScrollTop()
    scrollTop = (scrollTop) < 0 ? 0 : scrollTop

    if (this.lastScrollTop === scrollTop) {
      // Nothing changed
      return
    }

    // When true update DOM. (Only when properties have been changed)
    let update = false

    // Determine working values
    const atTheTop = scrollTop === 0
    const withinElement = scrollTop <= this.determineThreshold()
    const scrollingUp = this.lastScrollTop > scrollTop

    // Check for initial values.
    if (!withinElement && !this.lastScrollTop) {
      // If element is out of view we make it sticky immediately
      this.floating = true
      this.sticky = true
      this.lastScrollTop = scrollTop

      window.requestAnimationFrame(this.update)
      return
    }

    // Determine whether we need to float
    if (!withinElement && !this.floating) {
      update = true
      this.floating = true
    }

    // Reset properties if we are at the top of the page
    if (atTheTop) {
      update = true
      this.floating = false
      this.sticky = false
    }

    // Determine if we need to stick
    if (this.floating) {
      if (scrollingUp && !this.sticky) {
        update = true
        this.sticky = true
      } else if (!scrollingUp && this.sticky) {
        update = true
        this.sticky = false
      }
    }

    // Do the update
    if (update) {
      window.requestAnimationFrame(this.update)
    }

    this.lastScrollTop = scrollTop
  }

  openBackdrop () {
    if (!this.backDropIsOpen) {
      this.backdrop.classList.add('nav-header__search-backdrop--open')

      this.eventBus.off('open-search-backdrop', this.openBackdrop.bind(this))
      this.eventBus.on('close-search-backdrop', this.closeBackdrop.bind(this))

      this.backDropIsOpen = true
    }
  }

  closeBackdrop () {
    if (this.backDropIsOpen) {
      this.backdrop.classList.remove('nav-header__search-backdrop--open')

      this.eventBus.on('open-search-backdrop', this.openBackdrop.bind(this))
      this.eventBus.off('close-search-backdrop', this.closeBackdrop.bind(this))
      this.backDropIsOpen = false
    }
  }
}
