export default class RangeSlider {
  static name = 'range-slider'

  constructor(el, eventBus) {
    this.el = el
    this.input = this.el.querySelector(".range-slider__input")
    this.ticks = this.el.querySelector(".range-slider__ticks")
    this.eventBus = eventBus
    this.drawSliderTicks(this.input)
  }

  drawSliderTicks = (slider) => {
    const step = parseInt(slider.step)
    const stepSize =  parseInt(slider.max) - parseInt(slider.min)
    const steps = (stepSize / step) + 1

    for (let i = 0; i < steps; i++) {
      const tick = document.createElement("span");

      // Only first and last get text
      if (i === 0) {
        tick.innerText = slider.min
      }

      if (i === (steps - 1)) {
        tick.innerText = slider.max
      }

      tick.classList.add("range-slider__tick")
      this.ticks.appendChild(tick)
    }
  }
}
