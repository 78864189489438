export default class InteractiveCalendar {
  static name = "interactive-calendar"

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.navButtons = [...this.el.querySelectorAll('.interactive-calendar__nav .interactive-calendar__day')]
    this.activeContentItem = this.el.querySelector('.interactive-calendar__day-content.active')
    this.init()
  }

  init () {
    this.setEventHandlers()

    // Make first navigation button active
    this.activeNavButton = this.navButtons[0]
    this.activeNavButton.classList.add('active')
  }

  setEventHandlers () {
    this.navButtons.forEach((btn) => {
      btn.addEventListener('click', () => {
        this.handleNavClick(btn);
      })
    })
  }

  handleNavClick (btn) {
    if (this.activeNavButton) this.activeNavButton.classList.remove('active')
    if (this.activeContentItem) this.activeContentItem.classList.remove('active')
    this.activeNavButton = btn
    this.activeNavButton.classList.add('active')
    this.activeContentItem = this.el.querySelector(this.activeNavButton.dataset.for)
    this.activeContentItem.classList.add('active')
  }
}
