export default class StickyBar {
  static name = 'sticky-bar'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.locationEl = this.el.querySelector('.apply__usp--location')
    this.elmToObserve = document.querySelector(this.el.dataset.elementToObserve)

    // create intersection observer for floating filter button
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.el.classList.toggle('sticky-bar--visible', !entry.isIntersecting)
      })
    }, { threshold: 1 })

    this.observer.observe(this.elmToObserve)

    this.eventBus.on('update-location', this.onUpdateLocation)
  }

  onUpdateLocation = (value) => {
    this.locationEl.innerHTML = value
  }
}
