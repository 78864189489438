export default class NavOverlay {
  static name = "nav-overlay"

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.el = el

    this.eventBus.on('open-nav-list', this.show)
    this.eventBus.on('open-filters', this.show)

    this.el.addEventListener('click', this.onClick)
  }

  onClick = () => {
    this.eventBus.emit('close-nav-list')
    this.hide()
  }

  show = () => {
    this.el.classList.add('nav-overlay--visible')
    document.body.classList.add('no-overflow')

    this.eventBus.off('open-nav-list', this.show)
    this.eventBus.on('closed-nav-list', this.hide)

    this.eventBus.off('open-filters', this.show)
    this.eventBus.on('closed-filters', this.hide)
  }

  hide = () => {
    this.el.classList.remove('nav-overlay--visible')
    document.body.classList.remove('no-overflow')

    this.eventBus.on('open-nav-list', this.show)
    this.eventBus.on('closed-nav-list', this.hide)

    this.eventBus.on('open-filters', this.show)
    this.eventBus.on('closed-filters', this.hide)
  }
}
