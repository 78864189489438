import Game from '../../core/libs/game'

export default class DeliveryGame {
  static name = 'delivery-game'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.game = new Game(this.el, {gameOverElm: this.el.querySelector('.g__game-over')})
    this.startBtn = this.el.querySelector('.game__start-button')
    this.startModal = this.el.querySelector('.game__start-window')
    this.backBtn = this.el.querySelector('.game__back-button')

    this.init()
  }

  init () {
    this.game.show()
    this.backBtn.addEventListener('click', () => {
      window.scroll({top: 0, left: 0, behavior: 'smooth'})
    })
    this.startBtn.addEventListener('click', () => {
      this.startModal.classList.add('game__start-window--hidden')
      this.game.play()
    })
  }
}
