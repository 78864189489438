export default class Game {
  constructor (target = document.body, options = {}) {
    this.target = target
    this.options = options
    this.container = document.createElement('div')
    this.container.classList.add('g__container')
    this.container.innerHTML = '<div class="g__road"></div>'

    this.itemWrapper = document.createElement('div')
    this.itemWrapper.classList.add('g__item-wrapper')
    this.container.appendChild(this.itemWrapper)

    this.car = document.createElement('div')
    this.car.classList.add('g__car')
    this.container.appendChild(this.car)

    this.bar = document.createElement('div')
    this.bar.classList.add('g__bar')
    this.container.appendChild(this.bar)

    this.score = document.createElement('div')
    this.score.classList.add('g__score')
    this.container.appendChild(this.score)
    this.currentScore = 0

    this.lives = document.createElement('div')
    this.lives.classList.add('g__lives')
    this.container.appendChild(this.lives)
    this.currentLives = 3

    this.gameOver = this.getGameOver()
    this.gameOver.addEventListener('click', () => {
      this.restart()
    })
    this.container.appendChild(this.gameOver)

    this.stylesheet = document.createElement('link')
    this.stylesheet.rel = 'stylesheet'
    this.stylesheet.type = 'text/css'
    this.stylesheet.href = `${window.location.origin}/static/css/styles.css`

    this.buttonLeft = document.createElement('div')
    this.buttonLeft.classList.add('g__nav', 'g__nav--left')
    this.buttonLeft.addEventListener('click', this.moveLeft.bind(this))
    this.car.appendChild(this.buttonLeft)

    this.buttonRight = document.createElement('div')
    this.buttonRight.classList.add('g__nav', 'g__nav--right')
    this.buttonRight.addEventListener('click', this.moveRight.bind(this))
    this.car.appendChild(this.buttonRight)

    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 37) {
        this.moveLeft()
      }
      if (e.keyCode === 39) {
        this.moveRight()
      }
    })
  }

  start () {
    this.currentScore = 0
    this.score.innerHTML = ''
    this.target.classList.add('g-started')

    this.show()
    this.play()
  }

  show () {
    document.head.appendChild(this.stylesheet)
    this.target.appendChild(this.container)
    this.container.style.position = this.target === document.body ? 'fixed' : 'absolute'
  }

  stop () {
    if (!this.target.contains(this.container)) {
      return
    }

    this.target.classList.remove('g-started')
    this.target.removeChild(this.container)
    document.head.removeChild(this.stylesheet)
    clearInterval(this.interval)
  }

  getRandom (max) {
    return Math.floor(Math.random() * Math.floor(max))
  }

  isRandom (speed) {
    return this.getRandom(speed) === 0
  }

  update () {
    if (this.isRandom(15)) {
      this.addItem()
    }
  }

  addItem () {
    const itemClass = (this.isRandom(2)) ? 'g__rock' : 'g__appliance'
    const item = document.createElement('div')
    item.classList.add('g__item')
    item.classList.add(itemClass)
    item.style.left = `calc(50% - 195px + ${this.getRandom(390)}px)`
    this.observer.observe(item)
    this.itemWrapper.appendChild(item)
  }

  removeItem (target) {
    this.observer.unobserve(target)
    if (this.caught(target)) {
      if (target.classList.contains('g__appliance')) {
        this.currentScore++
        this.score.innerHTML = `${this.currentScore}x`
        this.car.classList.remove('bump')
      } else {
        this.lives.classList.remove(`g__lives--${this.currentLives}`)
        this.currentLives--
        this.lives.classList.add(`g__lives--${this.currentLives}`)
        if (this.currentLives < 1) {
          clearInterval(this.interval)
          this.container.classList.add('game-over')
        }
      }
      this.car.classList.add('bump')
      setTimeout(() => {
        this.car.classList.remove('bump')
      }, 100)
      this.itemWrapper.removeChild(target)
    }
  }

  caught (target) {
    const rectItem = target.getBoundingClientRect()
    const rectCar = this.car.getBoundingClientRect()
    return rectItem.x < rectCar.x + rectCar.width && rectItem.x + rectItem.width > rectCar.x && rectItem.y < rectCar.y + rectCar.height && rectItem.y + rectItem.height > rectCar.y
  }

  restart () {
    this.container.classList.remove('game-over')
    this.lives.setAttribute('class', 'g__lives')
    this.currentLives = 3
    this.currentScore = 0
    this.score.innerHTML = ''
    this.interval = setInterval(this.update.bind(this), 50)
  }

  moveLeft () {
    if (this.car.classList.contains('g__car--right')) {
      this.car.classList.remove('g__car--right')
    } else if (!this.car.classList.contains('g__car--left')) {
      this.car.classList.add('g__car--left')
    }
  }

  moveRight () {
    if (this.car.classList.contains('g__car--left')) {
      this.car.classList.remove('g__car--left')
    } else if (!this.car.classList.contains('g__car--right')) {
      this.car.classList.add('g__car--right')
    }
  }

  play () {
    setTimeout(() => {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.boundingClientRect.y > 100) {
            this.removeItem(entry.target)
          }
        })
      }, {
        root: this.itemWrapper,
        threshold: 1
      })
      this.interval = setInterval(this.update.bind(this), 50)
    }, 500)
  }

  getGameOver () {
    let domElm = this.options.gameOverElm
    if (!domElm) {
      domElm = document.createElement('div')
      domElm.classList.add('g__game-over')
    }
    return domElm
  }
}
