export default class UspBlock {
  static name = 'usp-block'

  constructor (el, eventBus) {
    this.eventBus = eventBus
    this.el = el
    this.items = this.el.querySelectorAll('.usp-block__item')
    this.activeElm = null
    this.interval = null

    if(this.isMobile()) {
      this.init()
    }
    window.addEventListener('resize', this.handleOnResize.bind(this))
  }

  init () {
    this.activeElm = this.items[0]
    this.show(this.activeElm)
    clearInterval(this.interval)
    this.interval = setInterval(this.slide.bind(this), 6000)
  }

  slide() {
    this.items.forEach(item => item.classList.remove('usp-block__item--hidden'))
    this.hide(this.activeElm)
    const idx = [...this.items].indexOf(this.activeElm)
    const next = idx+1 === this.items.length ? 0 : idx+1
    this.activeElm = this.items[next]
    this.show(this.activeElm)
  }

  show (activeElm) {
    activeElm.classList.add('usp-block__item--visible')
  }

  hide (activeElm) {
    activeElm.classList.remove('usp-block__item--visible')
    activeElm.classList.add('usp-block__item--hidden')
  }

  isMobile () {
    return window.matchMedia("(pointer: coarse)").matches && window.innerWidth < 768
  }

  handleOnResize () {
    if (this.isMobile()) {
      this.items.forEach(item => item.classList.remove('usp-block__item--hidden', 'usp-block__item--visible'))
      this.init()
    } else {
      this.items.forEach(item => item.classList.remove('usp-block__item--hidden', 'usp-block__item--visible'))
      clearInterval(this.interval)
    }
  }
}
