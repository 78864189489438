import GADataLayer from '../../core/libs/ga-data-layer'

export default class SalaryCalculator {
  static name = 'salary-calculator'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.lang = this.el.dataset.lang
    this.errorState = false
    this.isOff = false

    // Gather and format data
    this.salary = parseFloat(this.el.dataset.salary).toFixed(2)
    this.hours = parseFloat(this.el.dataset.defaultHours)

    const hoursEl = this.el.querySelector('.salary-calculator__hours-group')
    this.hoursInputEl = hoursEl.querySelectorAll('.salary-calculator__button-input')

    // Get result targets
    this.monthlySalaryEl = this.el.querySelector('[data-target=\'salary-monthly\']')
    this.screenEl = this.el.querySelector('.salary-calculator__screen')

    // Check if we need to show the drivers license questions
    this.useExtraQuestion = this.el.dataset.extraAnswersLength > 0

    if (this.useExtraQuestion) {
      const extraQuestionEl = this.el.querySelector('.salary-calculator__extra-question-group')
      this.extraQuestionInput = extraQuestionEl.querySelectorAll('input')
    }

    // Extra elements
    this.applyButton = this.el.querySelectorAll('.salary-calculator__apply-button')
    this.closeButton = this.el.querySelectorAll('.salary-calculator__close')
    this.flipButton = this.el.querySelectorAll('.salary-calculator__explain-link')

    // Keep track of ..
    this.flipped = false

    // Add Google Ads tracking
    this.ga = new GADataLayer()

    this.setup()
  }

  setup = () => {
    this.el.addEventListener('click', () => this.eventBus.emit('set-expandable', true), { once: true })

    Array.from(this.hoursInputEl).forEach(el => {
      el.addEventListener('input', this.processButtonInput)
    })

    // Handle additional logic when drivers license check is active
    if (this.useExtraQuestion) {
      Array.from(this.extraQuestionInput)
        .forEach(el => {
          el.addEventListener('change', () => {
            this.onExtraQuestionChanged(el.value)
          })
          if (el.checked) {
            this.salary = parseFloat(el.value).toFixed(2)
          }
        })
    }

    // Bind extra elements
    Array.from(this.applyButton).forEach(el => {
      el.addEventListener('click', (event) => {
      const eventValue = event.target.dataset.eventName
        this.triggerApply(event, eventValue)
      })
    })

    Array.from(this.flipButton).forEach(el => {
      el.addEventListener('click', (event) => {
        this.toggleFlipState(event)
        // Add Google Ads tracking
        if (this.flipped) {
          this.ga.push('to_calculator_backside', { location: 'salary_calculator_overview' })
        } else {
          this.ga.push('back_to_calculator', { location: 'additional_salary_information' })
        }
      })
    })

    Array.from(this.closeButton).forEach(el => {
      el.addEventListener('click', () => {
        this.eventBus.emit('popover-close')
        // Add Google Ads tracking
        if (this.flipped) {
          this.ga.push('close_button', { location: 'additional_salary_information' }) // Backside
        } else {
          this.ga.push('close_button', { location: 'salary_calculator_overview' }) // Frontside
        }
      })
    })

    // Initial calculation
    this.calculate()
  }

  processButtonInput = (e) => {
    const value = e.target.value
    const parsedValue = parseFloat(value)

    console.log(parsedValue)

    if (!isNaN(parsedValue)) {
      this.isOff = false
      this.hours = parsedValue
    } else if (value === 'clear') {
      this.isOff = false
      this.hours = 0
      this.hoursInputEl[0].checked = true

      const lastExtraQuestion = this.extraQuestionInput[this.extraQuestionInput.length - 1]
      this.salary = parseFloat(lastExtraQuestion.value).toFixed(2)
      lastExtraQuestion.checked = true
      this.errorState = false
    } else if (value === 'off') {
      this.isOff = true
    }

    this.calculate()
  }

  toggleFlipState = (e) => {
    e.preventDefault()
    this.el.classList.toggle('salary-calculator--flipped')
    this.flipped = !this.flipped
  }

  onExtraQuestionChanged = (value) => {
    if (value.toLowerCase() === 'none') {
      this.errorState = true
    } else {
      this.salary = parseFloat(value).toFixed(2)
      this.errorState = false
    }

    this.calculate()
  }

  showError = () => {
    this.el.querySelector('.salary-calculator__euro').style.display = 'none'
    this.el.querySelector('.salary-calculator__unit').style.display = 'none'
    this.el.querySelector('.salary-calculator__error').style.display = 'block'
    this.monthlySalaryEl.innerHTML = ':('
  }

  hideError = () => {
    this.el.querySelector('.salary-calculator__euro').style.display = 'block'
    this.el.querySelector('.salary-calculator__unit').style.display = 'block'
    this.el.querySelector('.salary-calculator__error').style.display = 'none'
  }

  hideScreen = () => {
    this.screenEl.classList.add('salary-calculator__screen--hidden')
  }

  showScreen = () => {
    this.screenEl.classList.remove('salary-calculator__screen--hidden')
  }

  formatMoney = (number, round = false, withEuro = true) => {
    if (round) {
      number = Math.round(number)
    }

    if (withEuro) {
      return `&euro; ${Number(number).toLocaleString(this.lang)}`
    }

    return Number(number).toLocaleString(this.lang)
  }

  calculate = () => {
    if (this.errorState) {
      this.showError()
      return
    }

    this.hideError()

    if (this.isOff) {
      this.hideScreen()
      return
    }

    this.showScreen()

    const monthlySalary = this.salary * this.hours * 52 / 12
    this.monthlySalaryEl.innerHTML = this.formatMoney(monthlySalary, true, false)
  }

  triggerApply = (e, eventValue) => {
    e.preventDefault()
    this.eventBus.emit('popover-close')

    const headerForm = document.querySelector('.header-form')

    if (headerForm) {
      headerForm.scrollIntoView({ behavior: 'smooth'})
    } else {
      this.eventBus.emit('trigger-apply-button', eventValue)
    }
  }
}
