import Cookies from 'js-cookie'

export default class AboutBanner {
  static name = 'about-banner'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.bannerHeight = 0

    if (Cookies.get('hideBanner')) {
      return
    }

    this.closeButton = this.el.querySelector('.about-banner__close-button')

    if (this.closeButton) {
      this.closeButton.addEventListener('click', this.closeBanner)
    }

    this.eventBus.on('closed-popover', (e) => {
      if(e === 'about-popover') {
        this.closeBanner()
      }
    })

    this.initBanner()
  }

  initBanner = () => {
    this.el.style.width = '100%'
    this.el.style.visibility = 'hidden'
    this.el.style.position = 'absolute'
    this.el.style.height = 'auto'

    this.bannerHeight = Math.ceil(this.el.getBoundingClientRect().height)

    this.el.style.width = ''
    this.el.style.visibility = ''
    this.el.style.position = ''
    this.el.style.height = ''

    this.el.style.height = `${this.bannerHeight}px`
    this.el.style.transition = 'height 0.5s ease'

    this.header = document.querySelector('.vacancy-header__container')

    if(this.header) {
      this.header.style.paddingTop = `${this.bannerHeight}px`
      this.header.style.transition = 'padding-top 0.5s ease'
    }
  }

  closeBanner = () => {
    this.el.style.height = '0'

    if(this.header) {
      this.header.style.paddingTop = '0'
    }

    this.setCookie()
  }

  setCookie = () => {
    const options = {
      expires: 365,
      secure: location.protocol === 'https:',
      sameSite: 'lax'
    }

    Cookies.set('hideBanner', true, options)
  }

}
