import serialize from 'form-serialize'
import Cookies from 'js-cookie'
import ConfettiGenerator from 'confetti-js'

const defaultFetchOptions = {
  cache: 'no-cache',
  credentials: 'same-origin',
  mode: 'cors',
  redirect: 'follow',
  referrer: 'no-referrer'
}

export default class Callback {
  static name = 'callback'

  confettiSettings = {
    'max': '60',
    'size': '1',
    'animate': true,
    'props': ['square'],
    'colors': [[255,102,0], [40,93,171],  [255, 255, 255]],
    'clock': '100',
    'rotate': true,
    'start_from_edge': true,
    'respawn': false
  }

  constructor(el, eventBus) {
    this.eventBus = eventBus
    this.form = el
    this.form.addEventListener('submit', this.submit)
    this.popover = el.closest(".popover")
    this.title = this.popover.querySelector(".popover__title")

    this.setTitle()
  }

  handleResponse = (response) => {
    if (response.error) {
      return this.handleError(response)
    }
    this.form.innerHTML = response.html
    this.setTitle(response.title)

    // Check if we need to throw confetti
    const confettiPlaceholder = this.popover.querySelector(".confetti")
    if (confettiPlaceholder) {
      this.throwConfetti(confettiPlaceholder)
    }
  }

  handleError = (response) => {
    console.log(response)
    this.form.innerHTML = this.form.dataset.errorText
    this.setTitle(this.form.dataset.errorTitle)
  }

  throwConfetti = (target) => {
    this.confettiSettings.target = target
    const confetti = new ConfettiGenerator(this.confettiSettings)
    confetti.render()
  }

  setTitle = (text) => {
    if (!text) {
      text = this.form.dataset.defaultTitle
    }
    this.title.innerHTML = text
  }

  submit = (e) => {
    e.preventDefault()

    // Get submit button and check if we need to close popover
    const button = this.form.querySelector("button[type='submit']")
    if (button.dataset.type === "close") {
      this.eventBus.emit("popover-close")
      return
    }

    const data = serialize(this.form)
    fetch(this.form.dataset.registerUrl, Object.assign({}, defaultFetchOptions, {
      method: "POST",
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data
    }))
      .then(response => response.json())
      .then((response) => {
        this.handleResponse(response)
      })
      .catch(error => this.handleError(error))
  }
}
