import Cookies from 'js-cookie'

export function findNextTabStop (el) {
  const universe = Array.from(document.querySelectorAll('input, button, select, textarea, a[href]'))
  const list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= '0' })
  const index = list.indexOf(el)
  return list[index + 1] || list[0]
}

export function findPrevTabStop (el) {
  const universe = Array.from(document.querySelectorAll('input, button, select, textarea, a[href]'))
  const list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= '0' })
  const index = list.indexOf(el)
  return list[index - 1] || list[list.length - 1]
}

export const bareCookies = Cookies.withConverter({
  read: (value) => value,
  write: (value) => value
})
