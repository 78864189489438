export default class CandidateDetails {
  static name = 'candidate-details'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.emailEl = this.el.querySelector('[data-target="email"]')
    this.phoneEl = this.el.querySelector('[data-target="phone"]')

    this.eventBus.on('update-candidate-details', this.onUpdateCandidateDetails)

    const data = sessionStorage.getItem('candidate')

    if(data) {
      const candidate = JSON.parse(data)
      this.onUpdateCandidateDetails(candidate)
    }
  }

  onUpdateCandidateDetails = (value) => {
    this.emailEl.innerHTML = value['email']
    this.phoneEl.innerHTML = value['phone_number']
  }
}
