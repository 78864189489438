export default class TalentPoolPopover {
  static name = 'talentpool-popover'

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.talentpoolCards = [...this.el.querySelectorAll('.talentpool-card-list__item')]
    this.talentpoolGroups = [...this.el.querySelectorAll('.talentpool-card--collapsed')]
    this.talentpoolRadioButtons = [...this.el.querySelectorAll('input[type="radio"]')]
    this.talentpoolSelectionBlock = this.el.querySelector('.talentpool-popover__pool-selection')
    this.iFrame = this.el.querySelector('.talent-pool-form__iframe')
    this.talentpoolIFrame = this.el.querySelector('.talent-pool-form__iframe')
    this.nextButton = this.el.querySelector('#talentpool-popover-submit-button')

    this.nextButton.addEventListener('click', (e) => {
      this.talentpoolSelectionBlock.classList.add('talentpool-popover__pool-selection--hidden')
      this.iFrame.classList.remove('talent-pool-form__iframe--hidden')
    })

    this.eventBus.on('closed-popover', this.close)

    this.talentpoolCards.forEach((card) => {
      card.addEventListener('click', (e) => {
        // Deselect all radio buttons
        this.talentpoolRadioButtons.forEach((field) => {
          field.checked = false
        })
        // Select the clicked radio button
        const input = e.target
        input.checked = true

        // Set the iFrame src based on user selection
        this.talentpoolIFrame.src = input.value
      })
    })
    this.talentpoolGroups.forEach((group) => {
      group.addEventListener('click', (e) => {
        group.classList.remove('talentpool-card--collapsed')
      })
    })
  }

  close = () => {
    // Reset to popover to first section
    this.talentpoolSelectionBlock.classList.remove('talentpool-popover__pool-selection--hidden')
    this.iFrame.classList.add('talent-pool-form__iframe--hidden')
    // Deselect all radio buttons
    this.talentpoolRadioButtons.forEach((field) => {
      field.checked = false
    })
  }
}
