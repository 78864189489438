import Swiper, { Pagination } from 'swiper'
import GADataLayer from '../../core/libs/ga-data-layer'
import Form from './form'

export default class SliderForm extends Form {
  static name = 'slider-form'

  constructor (el, eventBus) {
    super(el, eventBus)
    this.button = this.el.querySelector('.slider-form__apply-button')
    this.nextLabel = this.button.innerHTML
    this.submitLabel = this.button.dataset.submitLabel
    this.firstError = this.el.querySelectorAll('.slider-form__errors [data-target]')[0]
    this.ga = new GADataLayer()
    this.slideNames = [this.el.dataset.slide0, this.el.dataset.slide1, this.el.dataset.slide2]
    this.clicked = false

    this.initSlider()

    this.eventBus.on('form-error-clicked', (elm) => {
      this.showSlideContaining(elm)
    })
  }

  initSlider () {
    new Swiper('.slider-form__swiper', {
      direction: 'horizontal',
      autoHeight: true,
      loop: false,
      slidesPerView: 1,
      centeredSlides: true,
      allowTouchMove: false,
      modules: [Pagination],
      pagination: {
        el: '.slider-form__pagination',
        bulletClass: 'slider-form__bullet',
        bulletActiveClass: 'slider-form__bullet--active',
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className}"><span class="${className}-label">${this.slideNames[index]}</span></span>`
        },
      },
      on: {
        afterInit: (swiper) => {
          this.afterInitSwiper(swiper)
        },
      },
    })

    this.el.addEventListener('click', () => {
      if (!this.clicked) {
        this.ga.push('headerForm:start', {})
        this.clicked = true
      }
    })

    this.el.addEventListener('keydown', (e) => {
      if (e.keyCode === 9) {
        e.preventDefault()
      }
    })

    this.swiper.on('slideChange', () => {
      this.button.innerHTML = this.swiper.isEnd ? this.submitLabel : this.nextLabel
      this.ga.push('headerForm:slideChange', {slide: this.swiper.activeIndex + 1})
    })

    this.button.addEventListener('click', this.handleButtonPress.bind(this))
  }

  handleButtonPress (e) {
    e.preventDefault()

    if (!this.swiper) {
      return
    }

    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
      return
    }

    this.ga.push('apply_for_job', { location: 'vacancy_form_on_page' })
    this.button.form.submit()
  }

  showSlideContaining (elm) {
    const slideContainingElm = elm.closest('.slider-form__slide')
    const slides = this.el.querySelectorAll('.slider-form__slide')
    const idx = [...slides].indexOf(slideContainingElm)
    if (idx !== this.swiper.activeIndex) {
      console.log(`SLide to ${idx}`)
      this.swiper.slideTo(idx)
    } else {
      console.log(`SLide to closest`)
      this.swiper.slideToClosest()
    }
  }

  afterInitSwiper (swiper) {
    this.swiper = swiper
    if (this.firstError) {
      const elm = this.el.querySelector('.form__row--' + this.firstError.dataset.target)
      this.showSlideContaining(elm)
    }
    this.eventBus.on('subform-updated', this.updateSwiper.bind(this))
  }

  handleServerSideErrors(el) {
    this.errors = [...el.querySelectorAll('.form__errors [data-target]')]

    // Clicking on an error message in the summary will scroll to the respective field
    this.errors.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        const elm = this.el.querySelector('.form__row--' + item.dataset.target)
        // elm.scrollIntoView({ behavior: 'smooth' })
        const input = elm.querySelector('input')
        if (input) input.focus()
        this.eventBus.emit('form-error-clicked', elm)
      })
    })

    // Scroll to error summary, if available
    if (this.errors.length > 0) {
      this.el.querySelector('.form__errors').scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  updateSwiper () {
    this.swiper.updateAutoHeight()
  }
}
