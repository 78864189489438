export default class CardFilter {
  static name = 'card-filter'

  constructor(el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.tags = this.el.querySelectorAll('.card-block__tag')
    this.cards = this.el.querySelectorAll('.card')
    this.loadMore = this.el.querySelector('.card-block__loadmore')
    this.page = 1
    this.activeTags = []

    this.init()
  }

  init() {
    this.tags.forEach((tag) => {
      tag.addEventListener('click', () => {
        tag.classList.toggle('card-block__tag--active')
        this.updateResult()
      })
    })
    this.loadMore.addEventListener('click', () => {
      this.page++
      this.updateResult()
    })
    const params = new URLSearchParams(location.search);
    const page = params.get('page')
    if (page) {
      this.page = page
    }
    const tagParam = params.get('tag')
    if (tagParam) {
      this.activeTags = tagParam.split(',')
      this.tags.forEach((tag) => {
        if (this.activeTags.indexOf(tag.dataset.name) >= 0) {
          tag.classList.add('card-block__tag--active')
        }
      })
    }
    this.updateResult()
  }

  updateResult() {
    this.activeTags = []
    this.el.querySelectorAll('.card-block__tag--active').forEach((tag) => {
      this.activeTags.push(tag.dataset.name)
    })
    this.updateUrl()
    this.cards.forEach((card, idx) => {
      card.classList.toggle('card--filtered', this.activeTags.indexOf(card.dataset.tag) >= 0 && this.activeTags.length > 0)
    })
    const filtered = this.el.querySelectorAll('.card--filtered')
    if (filtered.length > 0) {
      this.cards.forEach((card, idx) => {
        card.classList.toggle('card--hidden', !card.classList.contains('card--filtered'))
      })
      filtered.forEach((card, idx) => {
        card.classList.toggle('card--hidden', idx >= this.page * this.el.dataset.pageSize)
      })
      this.loadMore.classList.toggle('card-block__loadmore--hidden', filtered.length <= this.page * this.el.dataset.pageSize)
    } else {
      this.cards.forEach((card, idx) => {
        card.classList.toggle('card--hidden', idx >= this.page * this.el.dataset.pageSize)
      })
      this.loadMore.classList.toggle('card-block__loadmore--hidden', this.cards.length <= this.page * this.el.dataset.pageSize)
    }
  }

  updateUrl() {
    const url = location.origin + location.pathname
    const tag = `tag=${this.activeTags.join(',')}`
    const page = `page=${this.page}`
    window.history.replaceState(window.history.state, window.title, `${url}?${page}&${tag}`)
  }
}
