import easyScroll from 'easy-scroll'

export default class AnchorBlock {
  static name = 'anchor-block'

  constructor(el) {
    this.el = el
    this.hash = null

    if(window.location.hash) {
      this.hash = window.location.hash.split('#')[1]
      window.location.hash = "";
      this.scrollToEl()
    }
  }

  scrollToEl = () => {
    if(this.el.id === this.hash) {
        const delta = Math.round(this.el.getBoundingClientRect().top)
        easyScroll({
          'scrollableDomEle': window,
          'direction': 'bottom',
          'duration': 1000,
          'easingPreset': 'easeInQuad',
          'scrollAmount': delta
      })
    }
  }
}
