export default class ExtraQualifications {
  static name = "extra-qualifications"

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus
    this.panels = [...this.el.querySelectorAll('.extra-qualifications__item')]

    this.setEventHandlers()

    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('location')) {
      this.el.querySelector(`#extra-qualifications-${queryParams.get('location')}`).classList.add('extra-qualifications__item--open')
    }
  }

  setEventHandlers () {
    this.panels.forEach((panel) => {
      panel.querySelector('.extra-qualifications__title').addEventListener('click', () => {
        panel.classList.toggle('extra-qualifications__item--open')
      })
    })
  }
}
